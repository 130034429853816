import React, { Component } from 'react';
import Button from '../../ui/button/Button';
import InputField from '../../ui/inputfield/InputField';

import PropTypes from 'prop-types';

class ForgetPassword extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            email: "",
            emailError: "",
        };

        this.handleEmailChange.bind(this);
    };

    handleEmailChange = event => {
        this.setState({ email: event.target.value, emailError: "" });
    }

    submitHandler = event => {
        event.preventDefault();

        if(this.state.email) {
            this.props.onSubmit(this.state.email);
        }
        else {
            this.setState({ emailError: "No email specified." });
        }
    }

    render() {
        const form = this.props.status.success ? <p>{this.props.status.message}</p> : 
        <div>
            <p>Please fill in the email of your account so we can send a link to recover your password.</p>
            <form>
            <InputField 
                className={"email"}
                label={"Email"} 
                type={"email"}
                autoComplete={"email"}
                onChange={this.handleEmailChange}
                error={this.state.emailError || (!this.props.status.success ? this.props.status.message : "")}
            />
            <Button onClick={this.submitHandler} loading={this.props.loading}>Send reset link</Button>
            </form>
        </div>;
        return (
            <div className="root">
                <h1>Password forgotten</h1>
                {form}
                <p className={"link"} onClick={this.props.onGoBack}>Go back to log in</p>
            </div>
        )
    }
}

ForgetPassword.propTypes = {
    emailError: PropTypes.string,
    onSubmit: PropTypes.func,
    onGoBack: PropTypes.func,
    status: PropTypes.object,
    loading: PropTypes.bool,
}

export default ForgetPassword;
