import React, { Component } from 'react';
import './Switch.scss';

export default class Switch extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
        }
    }

    toggleValue = () => {
        if(this.state.checked) {
            this.setState({checked: !this.state.checked});
            this.props.onChange(!this.state.checked);
        }
        else {
            this.setState({checked: !this.props.checked});
            this.props.onChange(!this.props.checked);
        }
    }

    render() {
        return (
            <div className="switch">
                <p>{this.props.title}</p>
                <label className="holder">
                    <input type="checkbox" checked={this.state.checked ? this.state.checked: this.props.checked} onChange={this.toggleValue}/>
                    <span className="slider round"/>
                </label>
            </div>
        )
    }
}
