import React from 'react';
import Modal from '../Modal';
import Button from '../../ui/button/Button';
import './Confirmation.scss';

export default function Confirmation(props) {
    return (
        <Modal>
            <div className="confirmation">
                <h1>{props.title}</h1>
                <p>{props.message}</p>
                <div className="footer">
                    <div/>
                    <Button outline onClick={props.onCancel}>Cancel</Button>
                    <Button onClick={props.onConfirm}>Confirm</Button>
                    <div/>
                </div>
            </div>
        </Modal>
    )
}
