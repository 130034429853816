import React from 'react'
import './ColorPicker.scss';

import classNames from 'classnames';

export default function ColorPicker(props) {

    let rowNumber = -1;
    let rows = [];
    for (let i = 0; i < props.options.length; i++) {
        if(i % 5 === 0) {
            let items = []; 
            rowNumber++;
            for (let item = 0; item < 5; item++) {
                if(props.options[rowNumber * 5 + item]) {
                    const color = props.options[rowNumber * 5 + item].value;
                    items.push(<div key={color} style={{borderColor: color}} className={classNames("option", {"selected": props.selectedColor === color})} onClick={() => props.onClick(color)}></div>)
                }
            }
            rows.push(<div key={rowNumber} className="row">{items}</div>)
        }
    }

    return (
        <div className="color-picker">
            {rows}
        </div>
    )
}
