const map = new Map();

const appsVersions = new Map();
appsVersions.set("application_name", "App name");
appsVersions.set("current_application_version_name", "Version" );
appsVersions.set("device_group_devices_with_latest_app_version", "Active on" );

const contentBuildInGroup = new Map();
contentBuildInGroup.set("content_build_application_name", "App name");
contentBuildInGroup.set("content_build_name", "Content build" );
contentBuildInGroup.set("content_build_version_code", "Version ID" );
contentBuildInGroup.set("content_build_activation_date", "Activate on" );
contentBuildInGroup.set("content_build_version_deploy_date", "Download on" );
contentBuildInGroup.set("device_group_devices_with_content_build_version", "Active on" );

const devices = new Map();
devices.set("device_name", "Device");
devices.set("device_group_name", "Device group");
devices.set("device_group_primary_application_name", "App");
devices.set("device_installed_device_group_primary_application_version_name", "App version");
devices.set("device_group_primary_application_content_build_name", "Content build");
devices.set("device_battery_health", "Battery health");
devices.set("device_disk_space", "Available diskspace");
devices.set("device_mac_address", "MAC address");
devices.set("device_last_seen", "Last seen on");

const deviceGroups = new Map();
deviceGroups.set("device_group_name", "Name");
deviceGroups.set("device_group_color", "Color");
deviceGroups.set("device_group_active_devices", "Active devices")
deviceGroups.set("device_group_primary_app_name", "Primary app");
deviceGroups.set("device_group_primary_app_content_build_name", "Content build");
deviceGroups.set("device_group_up_to_date_devices", "Up-to-date active devices");

const apps = new Map();
apps.set("application_name", "Name");
apps.set("application_package_id", "Package name");
apps.set("application_latest_version_name", "Latest version");

const appVersions = new Map();
appVersions.set("application_version_name", "Name");
appVersions.set("application_version_code", "Version code");
appVersions.set("application_file_download", "Download APK");
appVersions.set("application_file_size_kb", "Filesize");
appVersions.set("application_version_min_sdk", "Minimum SDK");
appVersions.set("application_version_changelog", "Changelog");

const deviceGroupApps = new Map();
deviceGroupApps.set("application_name", "App");
deviceGroupApps.set("current_application_version_name", "Version");

const deviceGroupDevices = new Map();
deviceGroupDevices.set("device_name", "Name");
deviceGroupDevices.set("device_group_primary_application_content_build_name", "Content build");
deviceGroupDevices.set("device_last_seen", "Last seen");
deviceGroupDevices.set("device_status", "Status");
deviceGroupDevices.set("device_installed_device_group_primary_application_version_name", "Version");

const deviceGroupContentBuilds = new Map();
deviceGroupContentBuilds.set("content_build_application_name", "App");
deviceGroupContentBuilds.set("content_build_name", "Content build name");
deviceGroupContentBuilds.set("content_build_version_code", "Version ID");
deviceGroupContentBuilds.set("content_build_version_deploy_date", "Deployed on");
deviceGroupContentBuilds.set("device_group_devices_with_content_build_version", "Adoption");

const addDevices = new Map();
addDevices.set("device_name", "Device");
addDevices.set("device_group_name", "Device group");
addDevices.set("device_mac_address", "MAC address");

const contentBuild = new Map();
contentBuild.set("content_build_activated_at", "Activation date");
contentBuild.set("content_build_name", "Name");
contentBuild.set("content_build_version_code", "Version ID");
contentBuild.set("content_build_application_name", "App name");

const files = new Map();
files.set("file_name", "Name");
files.set("file_type", "Type");
files.set("file_size_kb", "Size");
files.set("file_download", "Download file");

const releaseInContentBuild = new Map();
releaseInContentBuild.set("activation_date", "Activated on");
releaseInContentBuild.set("deploy_date", "Download on");
releaseInContentBuild.set("device_group", "Device group");
releaseInContentBuild.set("adoption", "Adoption");

const releases = new Map();
releases.set("device_group", "Device group");
releases.set("content_build_name", "Content build");
releases.set("content_build_version_activate_date", "Available on");
releases.set("content_build_version_deploy_date", "Download on");
releases.set("content_build_application", "Application name");
releases.set("content_build_version_adopted_devices", "Adoption");

map.set("appsVersions", appsVersions);
map.set("contentBuildInGroup", contentBuildInGroup);
map.set("devices", devices);
map.set("deviceGroups", deviceGroups)
map.set("apps", apps);
map.set("appVersions", appVersions);
map.set("deviceGroupApps", deviceGroupApps);
map.set("deviceGroupContentBuilds", deviceGroupContentBuilds);
map.set("deviceGroupDevices", deviceGroupDevices);
map.set("addDevices", addDevices);
map.set("contentBuild", contentBuild);
map.set("files", files);
map.set("releaseInContentBuild", releaseInContentBuild);
map.set("releases", releases);

export const getKey = (keyMap, code) => {
    if(map && keyMap && code) {
        return map.get(keyMap).get(code);
    }
}