import React, { Component } from 'react';
import './DeviceGroupEditor.scss';
import Button from '../../../ui/button/Button';
import Input from '../../../ui/input/Input';
import Table from '../../../ui/table/Table';
import { getColors, getAppsInGroup, getContentInGroup, getDevicesInGroup, saveDeviceGroup } from '../../../../api/Api';
import moment from 'moment';
import Dropdown from '../../../ui/dropdown/Dropdown';
import { Link } from 'react-router-dom';
import AppAdder from './appAdder/AppAdder';
import DeviceAdder from './deviceAdder/DeviceAdder';
import { verifyDeviceGroup } from '../../../../api/Verifier';
import { getAdoption } from '../../../../util/Converter';

export default class DeviceGroupEditor extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            primaryAppOptions: null,
            devices: null,
            params: {
                uuid: this.props.deviceGroup ? this.props.deviceGroup.device_group_uuid: null,
                name: this.props.deviceGroup ? this.props.deviceGroup.device_group_name: "",
                color: this.props.deviceGroup ? this.props.deviceGroup.device_group_color: null,
                primary_application_uuid : this.props.deviceGroup ? this.props.deviceGroup.device_group_primary_app_uuid: null,
                application_version_uuids : [],
                devices_uuids : [],
            },
            adding_apps: false,
            adding_devices: false,
        };
        this.nameChangeHandler.bind(this);
    };
    
    componentDidMount() {
        getColors(this.props.user.accessToken, this.props.customer)
        .then(res => {
            const colors = res.map(item => {
                return {value: item.color};
            })
            if(colors && colors.length > 0) {
                this.setState({colors});
                this.onColorSelect(this.state.selectedColor ? this.state.selectedColor : colors[0].value);
            }
        });
        if(this.props.deviceGroup) {
            getAppsInGroup(this.props.user.accessToken, this.props.customer, this.props.deviceGroup.device_group_uuid)
            .then(res => {
                console.log(res);
                
                let params = this.state.params;
                const appOptions = res.map(app => {
                    let apps = [{ 
                        application_version_code: app.current_application_version_code, 
                        application_version_name: app.current_application_version_name, 
                        application_version_uuid: app.current_application_version_uuid
                    }];
                    app.previous_application_versions.forEach(version => {
                        apps.push(version);
                    });
                    apps.reverse();
                    
                    params.application_version_uuids = params.application_version_uuids.concat(apps.map(app => {
                        return app.application_version_uuid;
                    }));
                    return {uuid: app.application_uuid, name: app.application_name};
                });
                this.setState({ apps: res, primaryAppOptions: appOptions, params });
            });
            getDevicesInGroup(this.props.user.accessToken, this.props.customer, this.props.deviceGroup.device_group_uuid)
            .then(res => {
                const devices = res.map(device => {
                    return device.device_uuid;
                });
                let params = this.state.params;
                params.devices_uuids = devices;
                this.setState({ devices: res, params });
            });
            getContentInGroup(this.props.user.accessToken, this.props.customer, this.props.deviceGroup.device_group_uuid)
            .then(res => {
                let activeBuilds = [];
                let plannedBuilds = [];
                let contentBuilds = res;
                contentBuilds = contentBuilds.filter(cb => cb.content_build_version_releases[0].content_build_version_release_state !== "WITHDRAWN");
                contentBuilds.forEach(contentBuild => {
                    contentBuild.device_group_devices_with_content_build_version = getAdoption(contentBuild.device_group_devices_with_content_build_version, contentBuild.device_group_active_devices);
                    if(contentBuild.content_build_version_releases[0].content_build_version_release_activated_date > moment().unix()) {
                        plannedBuilds.push(contentBuild);
                    }
                    else {
                        activeBuilds.push(contentBuild);
                    }
                });
                this.setState({ activeContentBuilds: activeBuilds, plannedContentBuilds: plannedBuilds });
            });
            this.onColorSelect(this.props.deviceGroup.device_group_color);
        }
    }

    saveHandler = () => {
        saveDeviceGroup(this.props.user.accessToken, this.props.customer, this.state.params)
        .then(res => {
            this.props.onFinish(res.device_group_uuid);
        })
        .catch(err => {
            console.log(err.response);
        })
    }

    onColorSelect = color => {
        let newParams = this.state.params;
        newParams.color = color;
        if (this.state.selectedColor !== color) {
            this.setState({ selectedColor: color, params: newParams });
        }
    }

    nameChangeHandler = event => {
        let newParams = this.state.params;
        newParams.name = event.target.value;
        this.setState({ params: newParams });
    }

    appRemoveHandler = apps => {
        let selectedApp = this.state.apps.filter(app => {
            return app.application_uuid === apps[0];
        })[0];
        
        let newApps = this.state.apps;
        let newParams = this.state.params;
        let primaryAppOptions = this.state.primaryAppOptions;

        newApps = newApps.filter(app => {
            return app.application_uuid !== selectedApp.application_uuid;
        })
        newParams.application_version_uuids = newParams.application_version_uuids.filter(version => {
            return version !== selectedApp.current_application_version_uuid;
        })
        if(selectedApp.previous_application_versions.length > 0) {
            const version = selectedApp.previous_application_versions.shift();
            selectedApp.current_application_version_code = version.application_version_code;
            selectedApp.current_application_version_name = version.application_version_name;
            selectedApp.current_application_version_uuid = version.application_version_uuid;
            newApps.unshift(selectedApp);
        }
        else if(newParams.primary_application_uuid === selectedApp.application_uuid) {
            newParams.primary_application_uuid = null;
            primaryAppOptions = primaryAppOptions.filter(app => {
                return app.uuid !== selectedApp.application_uuid;
            });
            newParams.primary_application_uuid = primaryAppOptions.length > 0 ? primaryAppOptions[0].uuid : null;
        }
        else {
            primaryAppOptions = primaryAppOptions.filter(app => {
                return app.uuid !== selectedApp.application_uuid;
            })
        }
        this.setState({ apps: newApps, params: newParams, primaryAppOptions });
    }

    deviceRemoveHandler = devices => {
        let newParams = this.state.params;
        let newDevices = this.state.devices.filter(device => {
            return device.device_uuid !== devices[0];
        })
        newParams.devices_uuids = newParams.devices_uuids.filter(device => {
            return device !== devices[0];
        })
        this.setState({ params: newParams, devices: newDevices });
    }

    openAppModal = () => {
        if(!this.state.adding_apps) {
            this.setState({adding_apps: true});
        }
    }

    saveAppModal = (app, version) => {
        let newParams = this.state.params;
        let apps = this.state.apps ? this.state.apps : [];
        if(!newParams.application_version_uuids.includes(version[0].application_version_uuid)) {
            newParams.application_version_uuids.push(version[0].application_version_uuid);
        }
        const currentApp = apps.filter(item => {
            return item.application_uuid === app.application_uuid
        })[0];
        if(!currentApp) {
            apps.unshift(app);
        }
        else {
            if(currentApp.current_application_version_uuid !== version[0].application_version_uuid) {
                currentApp.previous_application_versions.unshift({ 
                    application_version_code: currentApp.current_application_version_code, 
                    application_version_name: currentApp.current_application_version_name, 
                    application_version_uuid: currentApp.current_application_version_uuid
                });
                currentApp.current_application_version_code = version[0].application_version_code;
                currentApp.current_application_version_name = version[0].application_version_name;
                currentApp.current_application_version_uuid = version[0].application_version_uuid;
                apps = apps.filter(item => {
                    return item.application_uuid !== currentApp.application_uuid;
                })
                apps.unshift(currentApp);
            }
        }
        let primaryAppOptions = this.state.primaryAppOptions;
        primaryAppOptions = apps.map(app => {
            return({uuid: app.application_uuid, name: app.application_name});
        })
        if(primaryAppOptions.length === 1) {
            newParams.primary_application_uuid = app.application_uuid;
        }
        this.setState({params: newParams, apps: apps ? apps : [app], adding_apps: false, primaryAppOptions });
    }

    closeAppModal = () => {
        if(this.state.adding_apps) {
            this.setState({adding_apps: false});
        }
    }

    openDeviceModal = () => {
        if(!this.state.adding_devices) {
            this.setState({adding_devices: true});
        }
    }

    closeDeviceModal = () => {
        if(this.state.adding_devices) {
            this.setState({adding_devices: false});
        }
    }

    saveDevicesModal = devices => {
        devices.forEach(device => {
            device.device_status = false;
        });

        let newParams = this.state.params;
        let newDevices = this.state.devices ? [...this.state.devices, ...devices] : devices;
        const deviceUuids = devices.map(device => device.device_uuid);
        newParams.devices_uuids = [...newParams.devices_uuids, ...deviceUuids];

        this.setState({adding_devices: false, params: newParams, devices: newDevices});
    }

    primaryAppSelectionHandler = uuid => {
        let newParams = this.state.params;
        newParams.primary_application_uuid = uuid;
        this.setState({ params: newParams });
    }

    render() {
        return (
            <div className="device-group-editor">
                <div className="editor">
                    <div className="tab-header">
                        <h1>{(this.props.deviceGroup ? "Edit" : "Add") + " Device Group"}</h1>
                        <Button outline onClick={() => this.props.onFinish()}>Cancel</Button>
                        <Button onClick={() => this.saveHandler()} disabled={!verifyDeviceGroup(this.state.params.name, this.state.params.color)}>Save</Button>
                    </div>
                    <Input title="Name" onChange={this.nameChangeHandler} placeholder="Device group name" defaultValue={this.props.deviceGroup ? this.props.deviceGroup.device_group_name : ""}/>
                    {this.state.colors ? <Input className="color-picker-input" title="Color" color={this.state.selectedColor} colorOptions={this.state.colors} onColorSelect={this.onColorSelect}/> : null}
                    {this.state.primaryAppOptions && this.state.primaryAppOptions.length > 0 ? <Dropdown 
                        title="Primary app" 
                        placeholder="Select primary"
                        selectedValue={this.state.params.primary_application_uuid ? this.state.params.primary_application_uuid : null} 
                        options={this.state.primaryAppOptions}
                        onSelect={this.primaryAppSelectionHandler}
                    /> : null}
                    <Table 
                        keyMap="deviceGroupApps" 
                        data={this.state.apps} 
                        addTitle="Add app"
                        addAction={this.openAppModal}
                        errorMessage={<p>No apps selected.</p>}
                        onRemove={this.appRemoveHandler} 
                        title="Active Apps" 
                        small
                    />
                    <Table 
                        keyMap="deviceGroupContentBuilds" 
                        data={this.state.activeContentBuilds} 
                        title="Active Content Builds"
                        errorMessage={<p>No current active content build, go to <Link to="/content-builds">content builds</Link> and add this device group to a content build.</p>}
                        small 
                    />
                    <Table 
                        keyMap="deviceGroupContentBuilds" 
                        data={this.state.plannedContentBuilds} 
                        title="Planned Content Builds"
                        errorMessage={<p>No planned content builds.</p>}
                        small 
                    />
                    <Table 
                        keyMap="deviceGroupDevices" 
                        data={this.state.devices} 
                        title="Devices"
                        errorMessage={<p>No devices in group.</p>}
                        addTitle="Add device"
                        addAction={this.openDeviceModal}
                        onRemove={this.deviceRemoveHandler} 
                        small 
                        />
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onFinish()}>Cancel</Button>
                        <Button onClick={() => this.saveHandler()} disabled={!verifyDeviceGroup(this.state.params.name, this.state.params.color)}>Save</Button>
                    </div>
                    {this.state.adding_apps ? 
                    <AppAdder 
                        user={this.props.user} 
                        customer={this.props.customer}
                        onClose={this.closeAppModal}
                        onSave={this.saveAppModal}
                        /> : null}
                    {this.state.adding_devices ? 
                    <DeviceAdder 
                        user={this.props.user} 
                        customer={this.props.customer}
                        currentDevices={this.state.params.devices_uuids}
                        deviceGroup={this.state.params.uuid}
                        onClose={this.closeDeviceModal}
                        onSave={this.saveDevicesModal}
                        /> : null}
                </div>
                <div className="extra"/>
            </div>
        )
    }
}
