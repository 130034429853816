import React, { Component } from 'react';
import "./FileAdder.scss";
import Modal from '../../../../modal/Modal';
import Button from '../../../../ui/button/Button';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg';
import FileUpload from '../../../../ui/fileUpload/FileUpload';
import { convertFileSize } from '../../../../../util/Converter';
import { uploadFileToFileset } from '../../../../../api/Api';
import ProgressBar from '../../../../ui/progressBar/ProgressBar';

export default class FileAdder extends Component {

    uploadBatchCount = 3;
    notFoundCount = 0;
    requests = [];

    constructor(props) {
        super(props)

        this.state = {
            files: [],
            uploadProgress: [],
            isUploading: false
        };
    };

    saveFiles = () => {
        if(this.state.files.length > 0) {
            this.setState({isUploading: true});
            let files = [];
            this.requests = this.state.files.map(file => {
                return {
                    started: false,
                    call: () => uploadFileToFileset(this.props.user.accessToken, this.props.customer, this.props.fileSet, file, progressEvent => {
                        const { loaded } = progressEvent;
                        let uploadProgress = this.state.uploadProgress;
                        uploadProgress.find(progress => progress.file === file.name).current = loaded;
                        this.setState({ uploadProgress });
                    })
                    .then(res => {
                        console.log(res);
                        console.log(file);
                        files.push({
                            file_uuid: res.file_uuid,
                            file_name: file.name,
                            file_type: file.name.split(".")[file.name.split(".").length - 1],
                            file_size_kb: file.size / 1000,
                            file_download: 'https://dms.tapart.me/' + res.file_source,
                        });
                        const nextRequest = this.requests.find(item => !item.started);
                        if(nextRequest) {
                            nextRequest.started = true;
                            nextRequest.call();
                            console.log("Starting new one");
                        }
                        else {
                            this.notFoundCount++;
                            if(this.notFoundCount >= this.uploadBatchCount || this.notFoundCount >= this.requests.length) {
                                console.log(files);
                                this.props.onClose(files);
                            }
                        }
                    })
                    .catch(err => {
                        console.log(this.props.customer);
                        console.log(err.response.data);
                    }),
                }
            });
            for (let i = 0; i < (this.requests.length < this.uploadBatchCount ? this.requests.length : this.uploadBatchCount); i++) {
                if(this.requests[i]) {
                    this.requests[i].started = true;
                    this.requests[i].call();
                }
            }
        }
    }

    handleFiles = newFiles => {
        let files = this.state.files;
        let uploadProgress = this.state.uploadProgress;
        const filesToAdd = Array.from(newFiles);
        files = files.filter(file => !filesToAdd.filter(addFile => file.name === addFile.name)[0]);
        files = [...files, ...filesToAdd];
        uploadProgress = files.map(file => {
            return { file: file.name, current: 0, total: file.size };
        })
        this.setState({ files, uploadProgress });
    }

    removeFileHandler = fileToRemove => {
        let files = this.state.files;
        files = files.filter(file => fileToRemove !== file.name);
        this.setState({ files });
    }

    render() {
        const files = this.state.files.map((file, index) => {
            return <div key={index} className="file-view">
                <p>{file.name}</p>
                <p>{convertFileSize(file.size)}</p>
                {this.state.isUploading ? <ProgressBar total={this.state.uploadProgress[index].total} progress={this.state.uploadProgress[index].current}/> : <CloseIcon onClick={() => this.removeFileHandler(file.name)}/>}
            </div>
        });
        const fileHolder = <div className="file-holder">
            <p>Files</p>
            <div className="file-container">
                {files}
            </div>
        </div>

        return (
            <Modal>
                <div className="file-adder">
                    <h1>{this.props.title}</h1>
                    <CloseIcon className="close" onClick={() => this.props.onClose()}/>
                    <FileUpload
                        multiple
                        handleDrop={this.handleFiles}
                    />
                    {files && files.length > 0 ? fileHolder : null}
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button onClick={() => this.saveFiles()} disabled={this.state.isUploading || this.state.files.length < 1}>{this.state.isUploading ? "Uploading..." : "Save and upload"}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
