import React, { Component } from 'react'
import './Pagination.scss';
import Button from '../button/Button';
import classNames from 'classnames';

export default class Pagination extends Component {

    setPage = page => {
        if(page < 1 || page > this.props.pageCount) {
            return
        }
        if(this.props.currentPage !== page) {
            this.props.onPageChange(page);
        }
    }

    render() {
        const current = 1 + ((this.props.currentPage - 1) * this.props.pageSize);
        let text = current + '-' + (this.props.totalItems > this.props.currentPage * this.props.pageSize ? this.props.currentPage * this.props.pageSize : this.props.totalItems) + " of " + this.props.totalItems + " " + (this.props.items ? this.props.items : "items");
        const dots = <Button number disabled>...</Button>;
        let numbers = [];

        if(this.props.currentPage < 5) {
            for (let i = 1; i <= this.props.pageCount; i++) {
                if(i < 6 || i === this.props.pageCount) {
                    numbers.push(<Button key={i} number selected={i === this.props.currentPage} onClick={() => this.setPage(i)}>{i}</Button>);
                }
                else if(!numbers.includes(dots)) {
                    numbers.push(dots);
                }
            }
        }
        else if(this.props.currentPage > 3 && this.props.currentPage + 3 < this.props.pageCount) {
            for (let i = 1; i <= this.props.pageCount; i++) {
                if ((i >= this.props.currentPage - 1 && i <= this.props.currentPage + 1) || (i === 1 || i === this.props.pageCount)) {
                    numbers.push(<Button key={i} number selected={i === this.props.currentPage} onClick={() => this.setPage(i)}>{i}</Button>);
                }
                else if(i === this.props.currentPage - 3 || i === this.props.currentPage + 2) { 
                    numbers.push(dots);
                }
            }
        }
        else {
            for (let i = 1; i <= this.props.pageCount; i++) {
                if(i + 5 > this.props.pageCount || i === 1) {
                    numbers.push(<Button key={i} number selected={i === this.props.currentPage} onClick={() => this.setPage(i)}>{i}</Button>);
                }
                else if(!numbers.includes(dots)) {
                    numbers.push(dots);
                }
            }
        }

        return (
            <div className={classNames("pagination", this.props.className)}>
                <p>{text}</p>
                {this.props.totalItems > this.props.pageSize ? 
                    <div className="pagination-holder">
                        <Button pagination disabled={this.props.currentPage === 1} onClick={() => this.setPage(this.props.currentPage - 1)}>Previous</Button>
                        {numbers}
                        <Button pagination disabled={this.props.currentPage === this.props.pageCount} onClick={() => this.setPage(this.props.currentPage + 1)}>Next</Button>
                    </div>: 
                null}
            </div>
        )
    }
}
