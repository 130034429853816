import React from 'react';
import './Modal.scss';

export default function Modal(props) {
    return (
        <div className="modal">
            {props.children}
        </div>
    )
}
