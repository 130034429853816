import React, { Component } from 'react';

import './Input.scss';
import classNames from 'classnames';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg'
import ColorPicker from './colorPicker/ColorPicker';

export default class Input extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            hasFocus: false,
            open: false,
        }
    }

    onFocusChanged = focussed => {
        this.setState({hasFocus: focussed})
    }

    toggleSelector = () => {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <div className={classNames("input-parent", this.props.className)}>
                {this.props.title ? <p>{this.props.title}</p> : null}
                <div className={classNames("input", {"focussed": this.props.color ? this.state.open : this.state.hasFocus, "picker": this.props.color })} onClick={this.props.color ? () => this.toggleSelector() : null}>
                    {this.props.search ? <div className="icon"><SearchIcon/></div> : null}
                    {this.props.color ? <div style={{borderColor: this.props.color}} className="color"/> : null}
                    <input placeholder={this.props.placeholder} 
                        autoCorrect={this.props.autoCorrect ? "on" : "off"}
                        autoCapitalize={this.props.autoCapitalize ? "on" : "off"}
                        spellCheck={this.props.spellCheck}
                        readOnly={this.props.color ? this.props.color : this.props.readOnly} 
                        defaultValue={this.props.color ? this.props.color : this.props.defaultValue}
                        onChange={this.props.onChange}
                        onFocus={() => this.onFocusChanged(true)} 
                        onBlur={() => this.onFocusChanged(false)}
                    />
                    {this.state.open ? <ColorPicker options={this.props.colorOptions} selectedColor={this.props.color} onClick={this.props.onColorSelect}/> : null}
                </div>
            </div>
        )
    }
}
