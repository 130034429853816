import React, { Component } from 'react'
import './Table.scss';

import PropTypes from 'prop-types';

import { ReactComponent as ColorDot } from '../../../assets/svg/color_dot.svg'
import { ReactComponent as StatusDot } from '../../../assets/svg/status_dot.svg'
import { ReactComponent as Withdraw } from '../../../assets/svg/withdraw.svg'
import { ReactComponent as Eye } from '../../../assets/svg/eye.svg'
import { ReactComponent as Remove } from '../../../assets/svg/remove.svg'
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg'
import { ReactComponent as Add } from '../../../assets/svg/add.svg'
import { ReactComponent as Sorter } from '../../../assets/svg/sorter.svg'

import CheckBox from '../../ui/checkBox/CheckBox';
import { getKey } from '../../../util/Keys'
import moment from 'moment';
import classNames from 'classnames';
import { convertFileSize, convertAndroidSDK } from '../../../util/Converter';

class Table extends Component {

    constructor(props) {
        super(props)
        
        const ids = props.data && props.data.length > 0 ? props.data.map(data => {
            return props.idKey ? data[props.idKey] : data[Object.keys(data)[0]];
        }): [];

        this.state = {
            allSelected: false,
            ids: ids,
            sortBy: props.sortBy,
            sortState: props.sortState,
            selected: [],
        };
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.data && nextProps.data.length > 0){
            if(Object.values(nextProps.data[0])[0] !== prevState.ids[0]) {
                let newState = prevState;
                const ids = nextProps.data.map(data => {
                    return nextProps.idKey ? data[nextProps.idKey] : data[Object.keys(data)[0]];
                });
                newState.ids = ids;
                newState.selected = [];
                newState.allSelected = false;
                return newState;
            }
        }
        return prevState;
    }

    selectAll = () => {
        if(this.state.allSelected) {
            this.setState({ selected: [], allSelected: false });
            if(this.props.onSelect) {
                this.props.onSelect([]);
            }
        }
        else {
            this.setState({ allSelected: true, selected: this.state.ids });
            if(this.props.onSelect) {
                this.props.onSelect(this.state.ids);
            }
        }
    }
    
    selectId = id => {
        if(this.state.selected.includes(id)) {
            let currSelected = this.state.selected;
            currSelected = currSelected.filter(uuid => { return uuid !== id });
            this.setState({ selected: currSelected, allSelected: false });
            if(this.props.onSelect) {
                this.props.onSelect(currSelected);
            }
        }
        else {
            const currSelected = this.state.selected;
            const newSelected = [...currSelected, id];
            this.setState({ selected: newSelected, allSelected: newSelected.length === this.state.ids.length });
            if(this.props.onSelect) {
                this.props.onSelect(newSelected);
            }
        }
    }

    download = url => {
        console.log(window.location);
    }

    nextSortState = () => {
        switch (this.state.sortState) {
            case 0:
                this.setState({ sortState: 1 });
                return 1;
            case 1:
                this.setState({ sortState: 2 });
                return 2;
            case 2:
                this.setState({ sortState: 0 });
                return 0;
            default:
                this.setState({ sortState: 1 });
                return 1;
        }
    }

    sortBy = sortBy => {
        if(this.state.sortBy === sortBy) {
            this.handleSortChange(sortBy, this.nextSortState());
        }
        else {
            this.setState({ sortBy, sortState: 1 });
            this.handleSortChange(sortBy, 1);
        }
    }

    handleSortChange = (sortColumn, sortOrder) => {
        if(this.props.onSortChange) {
            let order = 'asc';
            if(sortOrder === 2) {
                order = 'dsc';
            }
            this.props.onSortChange(sortColumn, order);
        }
    }

    changelog = changelog => {
        this.props.onChangeLog(changelog);
    }

    render() {
        let headers = null;
        let header = null;
        let data = null;
        let cols = null;

        if(this.props.data && this.props.data.length > 0) {
            headers = Object.keys(this.props.data[0]).map(key => {
                const name = getKey(this.props.keyMap, key);
                if(name) {
                    if(this.props.onSortChange) {
                        return <React.Fragment key={key}><th>{name}<span className={key === this.state.sortBy ? classNames("sorter", {desc: this.state.sortState === 1, asc: this.state.sortState === 2}) : "sorter"} ><Sorter onClick={() => this.sortBy(key)}/></span></th></React.Fragment>;
                    }
                    else {
                        return <th key={key}>{name}</th>;
                    }
                }
                else {
                    return null;
                }
            });
            headers = headers.filter(el => {
                return el != null;
            })
            if(this.props.onView) {
                headers = [<th className="tah" key={'view'}/>, ...headers]
            }
            if(this.props.onWithdraw) {
                headers = [<th className="tah" key={'withdraw'}/>, ...headers]
            }
            if(this.props.onRemove) {
                headers = [<th className="tah" key={'remove'}/>, ...headers]
            }
            if(this.props.onEdit) {
                headers = [<th className="tah" key={'edit'}/>, ...headers]
            }
            if(this.props.selectable) {
                headers = [<th className="tah" key={'select'}><CheckBox checked={this.state.allSelected} onClick={() => this.selectAll()}/></th>, ...headers]
            }
            
            header = <tr>{headers}</tr>;

            data = this.props.data.map((data, i) => {
                let uuid = null;
                let values = Object.keys(data).map((key, index) => {
                    
                    if(index === 0 && !this.props.idKey) {
                        uuid = data[key];
                    }
                    else if(this.props.idKey) {
                        uuid = data[this.props.idKey];
                    }
                    if(getKey(this.props.keyMap, key)) {
                        if((key.includes('date') && !key.includes('up_to_date')) || key.includes('_seen') || key.includes('activated_at') || key.includes('created_at')) {
                            return <td key={index}>{moment.unix(data[key]).format('lll')}</td>
                        }
                        else if(key.includes('color')) {
                            return <td key={index}>{<ColorDot style={{fill: data[key]}}/>}</td>
                        }
                        else if(key.includes('status')) {
                            const color = data[key] ? "#20BF55" : "#DB162F"
                            return <td key={index}>{<StatusDot style={{fill: color}}/>}</td>
                        }
                        else if(key.includes('download')) {
                            const host = window.location.origin.includes("localhost") ? "https://dms.tapart.me/" : window.location.origin;
                            return <td key={index}>{data[key] ? <a className="link" download href={host + data[key]}>Download</a> : "Not available"}</td>
                        }
                        else if(key.includes('changelog')) {
                            return <td key={index}>{<p className="link" onClick={() => this.changelog(data[key])}>View</p>}</td>
                        }
                        else if(key === 'device_group') {
                            const color = data[key].split(" ")[0];
                            const name = data[key].substr(data[key].indexOf(" "), data[key].length);
                            return <td key={index}>{<div className="device-group"><StatusDot style={{fill: color}}/><p>{name}</p></div>}</td>
                        }
                        else if(key.includes('file_size_kb')) {
                            return <td key={index}>{convertFileSize(data[key] * 1000)}</td>
                        }
                        else if(key.includes('device_battery_health')) {
                            const color = data[key] === "GOOD" ? "#20BF55" : data[key] === "BAD" ? "#DB162F" : "#FF7C28";
                            return <td key={index}>{<StatusDot style={{fill: color}}/>}</td>
                        }
                        else if (key.includes('device_disk_space')) {
                            return <td key={index}>{convertFileSize(data[key] * 1000000)}</td>
                        }
                        else if(key.includes('min_sdk')) {
                            return <td key={index}>{convertAndroidSDK(data[key])}</td>
                        }
                        else {
                            return <td key={index}>{data[key] || data[key] === 0 ? data[key] : "-"}</td>
                        }
                    }
                    return null;
                });
                values = values.filter(el => {
                    return el != null;
                })
                return <tr key={i} className={classNames({'tr-selected': this.state.selected.includes(uuid)})}>
                    {this.props.selectable ? <td className="tai"><CheckBox checked={this.state.selected.includes(uuid)} onClick={() => this.selectId(uuid)}/></td> : null}
                    {this.props.onWithdraw ? <td className="tai"><Withdraw onClick={() => this.props.onWithdraw([uuid])}/></td> : null}
                    {this.props.onRemove ? <td className="tai"><Remove onClick={() => this.props.onRemove([uuid])}/></td> : null}
                    {this.props.onEdit ? <td className="tai"><Edit onClick={() => this.props.onEdit(uuid)}/></td> : null}
                    {this.props.onView ? <td className="tai"><Eye className="eye" onClick={() => this.props.onView(uuid)}/></td> : null}
                    {values}
                </tr>
            });
            cols = headers.map((o, i) => {
                return headers.length - 1 === i ? <col key={i} width='auto'/> : <col key={i} width='1'/>;             
            });
        }

        return (
            <div className={classNames("table", {"small-table": this.props.small})}>
                {this.props.title ? <h6>{this.props.title}</h6> : null}
                {this.props.data && this.props.data.length > 0 ? <table>
                    <colgroup>
                        {cols}
                    </colgroup>
                    <tbody>
                        {header}     
                        {data}
                    </tbody>
                </table> : <div className="error-message">
                    <div/>
                    {this.props.errorMessage ? this.props.errorMessage : <p>No data.</p>}
                </div>}
                {this.props.addAction ? <div className="add-action" onClick={this.props.addAction}>
                    <Add/> {this.props.addTitle}
                </div> : null}
            </div>
        )
    }
}

Table.propTypes = {
    data: PropTypes.array
}

export default Table;