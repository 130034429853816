import React, { Component } from 'react';
import './DeviceAdder.scss';
import Modal from '../../../../modal/Modal';
import Button from '../../../../ui/button/Button';
import { getDevices } from '../../../../../api/Api';
import Table from '../../../../ui/table/Table';

export default class DeviceAdder extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            
        };
    };

    componentDidMount () {
        getDevices(this.props.user.accessToken, this.props.customer, this.state.sortColumn, this.state.sortOrder, 0, 1000)
        .then(res => {
            let devices = res.results;
            devices = devices.filter(device => !this.props.currentDevices.includes(device.device_uuid));
            this.setState({devices});
        })
    }

    selectHandler = selectedDevices => this.setState({selectedDevices});

    saveDevices = devices => {
        const deviceList = this.state.devices.filter(device => devices.includes(device.device_uuid));
        this.props.onSave(deviceList);
    }

    handleSortChange = (sortColumn, sortOrder) => {
        this.setState({ sortColumn, sortOrder });
        console.log(sortColumn, sortOrder);
    }
    
    render() {
        return (
            <Modal>
                <div className="device-adder">
                    <h1>Add device</h1>
                    <Table data={this.state.devices} selectable keyMap="addDevices" small onSelect={this.selectHandler} onSortChange={this.handleSortChange}/>
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button onClick={() => this.saveDevices(this.state.selectedDevices)} disabled={!(this.state.selectedDevices && this.state.selectedDevices.length > 0)}>Save</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
