import React, { Component } from 'react';
import './FileUpload.scss';

import classNames from 'classnames';
import { convertFileSize } from '../../../util/Converter';

export default class FileUpload extends Component {

    dropRef = React.createRef();

    constructor(props) {
        super(props)
    
        this.state = {
            drag: false,
            files: null,
        }
    }

    handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true});
        }
    }

    handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--
        if (this.dragCounter > 0) return;
        this.setState({drag: false});
    }

    handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.handleFiles(e.dataTransfer.files);
            this.dragCounter = 0;
        }
    }

    handleFiles = files => {
        if(this.props.apkOnly) {
            if(files.length === 1 && files[0].name.substr(files[0].name.length - 4) === ".apk") {
                this.setState({ files });
                if (this.props.handleDrop) {
                    this.props.handleDrop(files);
                }
            }
        }
        else {
            this.setState({ files });
            if (this.props.handleDrop) {
                this.props.handleDrop(files);
            }
        }
    }

    componentDidMount() {
        this.dragCounter = 0;
        const div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        const div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    openFileBrowser = () => {
        this.fileUploadRef.click();
    }
    
    render() {
        return (
            <div className="file-upload">
                <div ref={this.dropRef} className={classNames("drop-box", {"drop-box-drag": this.state.drag})}>
                    {this.state.files && this.state.files.length === 1 ? 
                    <p>{this.state.files[0].name}<br/>{"(" + convertFileSize(this.state.files[0].size) + ")"}</p> :
                    <p>Drag and drop file here to upload or click to select file</p>}
                </div>
                <input type="file" ref={(ref) => {this.fileUploadRef = ref}} accept={this.props.apkOnly ? ".apk" : "*"} multiple={this.props.multiple} onChange={e => this.handleFiles(e.target.files)}/>
            </div>
        )
    }
}
