import React, { Component } from 'react';
import './ContentBuildEditor.scss';
import Button from '../../../ui/button/Button';
import Dropdown from '../../../ui/dropdown/Dropdown';
import { getApps, createContentBuild, getContentBuildVersionDetails, getFileSet, createFileSet, deleteFileFromFileset } from '../../../../api/Api';
import Input from '../../../ui/input/Input';
import Table from '../../../ui/table/Table';
import DateTimePicker from '../../../ui/dateTimePicker/DateTimePicker';
import { verifyContentBuild } from '../../../../api/Verifier';
import FileAdder from './fileAdder/FileAdder';
import ReleaseAdder from './releaseAdder/ReleaseAdder';

export default class ContentBuildEditor extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            params: {
                content_build_uuid: null,
                content_build_name: null,
                content_build_application_uuid: null,
                content_build_activated_timestamp: (new Date() * 0.001),
                content_build_version_change_log: "",
                content_build_version_file_set_uuids: []
            },
            latestVersion: null,
            releases: [],
            contentFiles: [],
            assetFiles: [],
            addingContent: false,
            addingAsset: false,
            savedBuild: props.contentBuildToEdit,
        }
    }

    componentDidMount() {
        console.log(this.props.contentBuildToEdit);
        
        if(this.props.contentBuildToEdit) {
            getContentBuildVersionDetails(this.props.user.accessToken, this.props.customer, this.props.contentBuildToEdit)
            .then(res => {
                console.log(res);
                let params = this.state.params;
                params.content_build_uuid = res.content_build_uuid;
                params.content_build_name = res.content_build_name;
                params.content_build_application_uuid = res.content_build_application_uuid;
                params.content_build_activated_timestamp = res.content_build_activated_at;
                params.content_build_version_file_set_uuids = res.content_build_version_filesets;
                this.setState({params, latestVersion: this.props.contentBuildToEdit, currentCode: res.content_build_version_code});
                if(this.props.alreadyReleased) {
                    console.log(res.content_build_version_releases);
                    const releases = res.content_build_version_releases.map(release => {
                        return {
                            activation_date: release.content_build_version_activated_at,
                            deploy_date: release.content_build_version_deployed_at,
                            device_group: release.content_build_version_released_in_device_groups[0].device_group_color + " " + release.content_build_version_released_in_device_groups[0].device_group_name,
                            adoption: release.content_build_version_released_in_device_groups[0].device_group_devices_with_cbv + "/" + release.content_build_version_released_in_device_groups[0].device_group_all_devices
                        }
                    });
                    console.log(releases);
                    this.setState({releases});
                }
                getFileSet(this.props.user.accessToken, this.props.customer, res.content_build_version_filesets[0], res.content_build_application_uuid, res.content_build_uuid)
                .then(res => {
                    console.log(res);
                    
                    const assetFiles = res.file_set_files;
                    assetFiles.forEach(file => {
                        file.file_type = file.file_name.split(".")[file.file_name.split(".").length - 1];
                    });
                    this.setState({ assetFiles });
                })
                .catch(err => {
                    console.log(err);
                    console.log(err.response);
                })
                getFileSet(this.props.user.accessToken, this.props.customer, res.content_build_version_filesets[1], res.content_build_application_uuid, res.content_build_uuid)
                .then(res => {
                    console.log(res);
                    const contentFiles = res.file_set_files;
                    contentFiles.forEach(file => {
                        file.file_type = file.file_name.split(".")[file.file_name.split(".").length - 1];
                    });
                    this.setState({ contentFiles });
                })
                .catch(err => {
                    console.log(err.response);
                })
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
            })
        }
        getApps(this.props.user.accessToken, this.props.customer)
        .then(res => {
            const appOptions = res.results.map(app => {
                return {
                    uuid: app.application_uuid,
                    name: app.application_name,
                }
            });
            this.setState({ appOptions });
        })
        .catch(err => {

        })
    }

    saveHandler = () => {
        if(!this.state.savedBuild) {
            createContentBuild(this.props.user.accessToken, this.props.customer, this.state.params)
            .then(res => {
                getContentBuildVersionDetails(this.props.user.accessToken, this.props.customer, res.content_build_version_uuid)
                .then(res => {
                    this.setState({ latestVersion: res.content_build_latest_version_uuid });
                })
                console.log(res);
                
                let params = this.state.params;
                params.content_build_uuid = res.content_build_uuid;
                params.content_build_version_uuid = res.content_build_version_uuid;
                params.content_build_version_file_set_uuids = res.file_set_uuids;
                this.setState({ savedBuild: true, params });
            })
            .catch(err => {
                console.log(err.response);
            });
        }
        else {
            if(this.props.alreadyReleased) {
                createFileSet(this.props.user.accessToken, this.props.customer, "ASSETS", this.state.params.content_build_version_file_set_uuids[0])
                .then(fileSet => {
                    let params = this.state.params;
                    params.content_build_version_file_set_uuids[0] = fileSet.file_set_uuid;
                    createFileSet(this.props.user.accessToken, this.props.customer, "CONTENT", this.state.params.content_build_version_file_set_uuids[1])
                    .then(fileSet => {
                        params.content_build_version_file_set_uuids[1] = fileSet.file_set_uuid;
                        createContentBuild(this.props.user.accessToken, this.props.customer, params)
                        .then(res => {
                            console.log(res);
                            this.props.onFinish(res);
                        })
                        .catch(err => {
                            console.log(err.response);
                        });
                    });
                })
            }
            else {
                this.props.onFinish(this.state.params.content_build_uuid);
            }
        }
    }
    
    appSelectionHandler = uuid => {
        let params = this.state.params;
        params.content_build_application_uuid = uuid;
        this.setState({ params });
    }

    nameChangeHandler = event => {
        let params = this.state.params;
        params.content_build_name = event.target.value;
        this.setState({ params });
    }

    dateTimeChangeHandler = date => {
        let params = this.state.params;
        params.content_build_activated_timestamp = Date.parse(date) * 0.001;
        this.setState({ params });
    }

    closeAssetFileModal = files => {
        if(files) {
            let assetFiles = this.state.assetFiles;
            const newAssetFiles = files;
            assetFiles = assetFiles.filter(assetFile => !newAssetFiles.find(newFile => newFile.file_name === assetFile.file_name));
            assetFiles = [...assetFiles, ...newAssetFiles];
            this.setState({ assetFiles, addingAsset: false });
        }
        else {
            this.setState({ addingAsset: false });
        }
    }

    closeContentFileModal = files => {
        if(files) {
            let contentFiles = this.state.contentFiles;
            const newContentFiles = files;
            contentFiles = contentFiles.filter(assetFile => !newContentFiles.find(newFile => newFile.file_name === assetFile.file_name));
            contentFiles = [...contentFiles, ...newContentFiles];
            this.setState({ contentFiles, addingContent: false });
        }
        else {
            this.setState({ addingContent: false });
        }
    }

    closeReleaseModal = release => {
        let releases = this.state.releases;
        if(release) {
            releases = releases.filter(item => item.device_group !== release.device_group)
            releases = [...releases, release];
        }
        this.setState({ addingRelease: false, releases });
    }

    addContentFiles = () => {
        this.setState({ addingContent: true });
    }

    addAssetFiles = () => {
        this.setState({ addingAsset: true });
    }

    addRelease = () => {
        this.setState({ addingRelease: true });
    }

    onContentFileRemove = uuid => {
        if(!uuid[0].includes(".")) {
            deleteFileFromFileset(this.props.user.accessToken, this.props.customer, this.state.params.content_build_version_file_set_uuids[1], uuid[0])
            .then(res => {
                if(res.status && res.status === "OK") {
                    let contentFiles = this.state.contentFiles;
                    contentFiles = contentFiles.filter(file => file.file_uuid !== uuid[0]);
                    this.setState({ contentFiles });
                }
            })
            .catch(err => {
                console.log(err.response);
            })
        }
    }

    onAssetFileRemove = uuid => {
        if(!uuid[0].includes(".")) {
            deleteFileFromFileset(this.props.user.accessToken, this.props.customer, this.state.params.content_build_version_file_set_uuids[0], uuid[0])
            .then(res => {
                if(res.status && res.status === "OK") {
                    let assetFiles = this.state.assetFiles;
                    assetFiles = assetFiles.filter(file => file.file_uuid !== uuid[0]);
                    this.setState({ assetFiles });
                }
            })
            .catch(err => {
                console.log(err.response);
            })
        }
    }

    render() {
        return (
            <div className="content-build-editor">
                <div className="editor">
                    <div className="tab-header">
                        <h1>{this.props.alreadyReleased ? this.state.params.content_build_name + " (" + this.state.currentCode + ")": "Edit Content Build"}</h1>
                        <Button outline onClick={() => this.props.onFinish()}>{this.props.alreadyReleased ? "Go back" : "Cancel"}</Button>
                        <Button onClick={() => this.saveHandler()} disabled={!verifyContentBuild(this.state.params.content_build_application_uuid, this.state.params.content_build_name, this.state.params.content_build_activated_timestamp, this.state.assetFiles, this.state.contentFiles)}>{this.props.alreadyReleased ? "Duplicate version" : "Save"}</Button>
                    </div>
                    <Dropdown 
                        title="Select App" 
                        placeholder="Application"
                        selectedValue={this.state.params.content_build_application_uuid ? this.state.params.content_build_application_uuid : null} 
                        options={this.state.appOptions}
                        onSelect={this.appSelectionHandler}
                        readOnly={this.state.savedBuild ? true : false}
                    />
                    <Input
                        title="Name"
                        placeholder="Content build name"
                        onChange={this.nameChangeHandler}
                        defaultValue={this.state.params.content_build_name}
                        readOnly={this.state.savedBuild ? true : false}
                    />
                    <DateTimePicker
                        onChange={this.dateTimeChangeHandler}
                        title="Activation date & time"
                        defaultValue={this.state.params.content_build_activated_timestamp}
                        minDate={new Date()}
                        readOnly={this.state.savedBuild ? true : false}
                    />
                    {this.state.savedBuild ? <div>
                        <h6>Filesets</h6>
                        <div className="filesets">
                            <Table 
                                small 
                                data={this.state.assetFiles}
                                addAction={this.props.alreadyReleased ? null : () => this.addAssetFiles()}
                                onRemove={this.props.alreadyReleased ? null : this.onAssetFileRemove}
                                addTitle={this.props.alreadyReleased ? null : "Add file"}
                                title="Asset files"
                                keyMap="files"
                            />
                            <Table 
                                small 
                                data={this.state.contentFiles}
                                addAction={this.props.alreadyReleased ? null : () => this.addContentFiles()}
                                onRemove={this.props.alreadyReleased ? null : this.onContentFileRemove}
                                addTitle={this.props.alreadyReleased ? null : "Add file"}
                                title="Content files"
                                keyMap="files"
                            />
                        </div>
                        <Table 
                            small 
                            data={this.state.releases}
                            addAction={() => this.addRelease()}
                            addTitle="New release"
                            keyMap="releaseInContentBuild"
                            title="Releases (with this content build)"
                        />
                    </div> : null}
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onFinish()}>{this.props.alreadyReleased ? "Go back" : "Cancel"}</Button>
                        <Button onClick={() => this.saveHandler()} disabled={!verifyContentBuild(this.state.params.content_build_application_uuid, this.state.params.content_build_name, this.state.params.content_build_activated_timestamp, this.state.assetFiles, this.state.contentFiles)}>{this.props.alreadyReleased ? "Duplicate version" : "Save"}</Button>
                    </div>
                    {this.state.addingAsset ? <FileAdder 
                        onClose={this.closeAssetFileModal} 
                        title="Upload asset files"
                        fileSet={this.state.params.content_build_version_file_set_uuids[0]}
                        user={this.props.user}
                        customer={this.props.customer}
                    /> : null}
                    {this.state.addingContent ? <FileAdder 
                        onClose={this.closeContentFileModal} 
                        title="Upload content files"
                        fileSet={this.state.params.content_build_version_file_set_uuids[1]}
                        user={this.props.user}
                        customer={this.props.customer}
                    /> : null}
                    {this.state.addingRelease ? <ReleaseAdder
                        onClose={this.closeReleaseModal} 
                        title="New release"
                        user={this.props.user}
                        customer={this.props.customer}
                        contentBuild={this.state.latestVersion}
                        availableTimestamp={this.state.params.content_build_activated_timestamp}
                    /> : null}
                </div>
            </div>
        )
    }
}
