import React, { Component } from 'react';
import "./Releases.scss";
import Input from '../../ui/input/Input';
import Pagination from '../../ui/pagination/Pagination';
import Table from '../../ui/table/Table';
import { getReleases, withdrawContentBuild } from '../../../api/Api';
import Confirmation from '../../modal/confirmation/Confirmation';
import { getAdoption } from '../../../util/Converter';

export default class Releases extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            releases: [],
            selectedCustomer: props.selectedCustomer
        }
    }

    updateReleases = () => {
        getReleases(this.props.user.accessToken, this.state.selectedCustomer)
        .then(res => {
            console.log(res);
            const releases = res.results.map(release => {
                return {
                    device_group: release.device_group_color + " " + release.device_group_name,
                    content_build_name: release.content_build_name,
                    content_build_version_deploy_date: release.content_build_version_deploy_date,
                    content_build_version_activate_date: release.content_build_version_activate_date,
                    content_build_application: release.content_build_application,
                    content_build_uuid: release.content_build_uuid,
                    content_build_version_adopted_devices: getAdoption(release.content_build_version_adopted_devices, release.content_build_version_total_devices),
                    content_build_version_code: release.content_build_version_code,
                    content_build_version_release_state: release.content_build_version_release_state,
                    content_build_version_release_uuid: release.content_build_version_release_uuid,
                    content_build_version_total_devices: release.content_build_version_total_devices,
                }
            })
            const activeReleases = releases.filter(release => release.content_build_version_release_state === "ACTIVE");
            const futureReleases = releases.filter(release => release.content_build_version_release_state === "FUTURE");
            const pastReleases = releases.filter(release => release.content_build_version_release_state === "PAST");
            this.setState({ activeReleases, futureReleases, pastReleases });
        })
        .catch(err => {
            console.log(err.response);
        })
    }

    componentDidMount() {
        this.updateReleases();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateReleases();
        }
    }
 
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    handleWithdraw = release => {
        withdrawContentBuild(this.props.user.accessToken, this.state.selectedCustomer, release.content_build_version_release_uuid)
        .then(res => {
            if(res.detail === "OK") {
                let activeReleases = this.state.activeReleases;
                let futureReleases = this.state.futureReleases;
                activeReleases = activeReleases.filter(rel => rel.content_build_version_release_uuid !== release.content_build_version_release_uuid);
                futureReleases = futureReleases.filter(rel => rel.content_build_version_release_uuid !== release.content_build_version_release_uuid);
                this.setState({ releaseToWithdraw: null, activeReleases, futureReleases });
            } 
        })
        .catch(err => {
            this.setState({ releaseToWithdraw: null });
            console.log(err.response);
        });
    }

    withdrawHandler = release => {
        const releases = [...this.state.activeReleases, ...this.state.futureReleases];
        const releaseToWithdraw = releases.filter(rel => rel.content_build_version_release_uuid === release[0])[0];
        this.setState({ releaseToWithdraw });
    }

    render() {
        const activeReleases = this.state.activeReleases && this.state.activeReleases.length > 0 ? <Table keyMap="releases" data={this.state.activeReleases} title="Active releases" idKey="content_build_version_release_uuid" onWithdraw={this.withdrawHandler}/> : null;
        const futureReleases = this.state.futureReleases && this.state.futureReleases.length > 0 ? <Table keyMap="releases" data={this.state.futureReleases} title="Future releases" idKey="content_build_version_release_uuid" onWithdraw={this.withdrawHandler}/> : null;
        const pastReleases = this.state.pastReleases && this.state.pastReleases.length > 0 ? <Table keyMap="releases" data={this.state.pastReleases} idKey="content_build_version_release_uuid" title="Past releases"/> : null;
        const activePagination = this.state.activeReleases ? <Pagination 
            className="pagination"
            items="active releases"
            currentPage={1}
            pageCount={1}
            totalItems={this.state.activeReleases.length}
            pageSize={this.state.activeReleases.length}
        />: null;
        const futurePagination = this.state.futureReleases ? <Pagination 
            className="pagination"
            items="future releases"
            currentPage={1}
            pageCount={1}
            totalItems={this.state.futureReleases.length}
            pageSize={this.state.futureReleases.length}
        />: null;
        const pastPagination = this.state.pastReleases ? <Pagination 
            className="pagination"
            items="past releases"
            currentPage={1}
            pageCount={1}
            totalItems={this.state.pastReleases.length}
            pageSize={this.state.pastReleases.length}
        />: null;
        return (
            <div className="release">
                <div className="tab-header">
                    <h1>Releases</h1>
                </div>
                <div className="release-top-bar">
                    <Input className="release-search" placeholder="Search..." search/>
                </div>
                {activeReleases}
                {activeReleases ? activePagination : this.state.activeReleases && this.state.activeReleases.length > 0 ? null : <p className="no-release-text">No active content builds.</p>}
                {futureReleases}
                {futureReleases ? futurePagination : this.state.futureReleases && this.state.futureReleases.length > 0 ? null : <p className="no-release-text">No future content builds.</p>}
                {pastReleases}
                {pastReleases ? pastPagination : this.state.pastReleases && this.state.pastReleases.length > 0 ? null : <p className="no-release-text">No past content builds.</p>}
                {this.state.releaseToWithdraw ? <Confirmation 
                    title="Withdraw content build version?" 
                    message={"Are you sure you want to withdraw version " + this.state.releaseToWithdraw.content_build_version_code + " of " + this.state.releaseToWithdraw.content_build_name + "?"}
                    onCancel={() => this.setState({ releaseToWithdraw: null })}
                    onConfirm={() => this.handleWithdraw(this.state.releaseToWithdraw)}
                /> : null}
            </div>
        )
    }
}
