export const verifyApplication = (name, packageId, versions) => {
    const packageIdRegex = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
    return name && name.length > 2 && packageIdRegex.test(packageId) && versions && versions.length > 0;
}

export const verifyApplicationVersion = (file, versionName, versionCode, minimumSdk) => {
    const versionNameRegex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
    const versionCodeRegex = /^[0-9]*$/gm;
    return file && versionCodeRegex.test(versionCode) && versionNameRegex.test(versionName) && (minimumSdk > 18 && minimumSdk < 30);
}

export const verifyDeviceGroup = (name, color) => {
    return name && name.length > 2 && color;
}

export const verifyContentBuild = (app, name, dateTime) => {
    return app && name && name.length > 2 && dateTime;
}