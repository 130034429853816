import React, { Component } from 'react';
import Button from '../../ui/button/Button';

import './Home.scss';
import Confirmation from './../../modal/confirmation/Confirmation';
import InfoBox from './infoBox/InfoBox';
import Table from '../../ui/table/Table';
import { getDeviceGroups, getAppsInGroup, getContentInGroup, withdrawContentBuild, withdrawApplicationVersion } from '../../../api/Api';
import { getAdoption } from '../../../util/Converter';

class Home extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            selectedCustomer: props.selectedCustomer,
            selectedGroup: null,
            apps: null,
            contentBuilds: null,
        };
    };

    componentDidMount() {
        this.updateDeviceGroups();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.selectedCustomer !== prevProps.selectedCustomer) {
            this.updateDeviceGroups();
        }
    }

    updateDeviceGroups = () => {
        getDeviceGroups(this.props.user.accessToken, this.state.selectedCustomer)
        .then(res => {
            console.log(res);
            this.setState({device_groups : res.results});
            this.handleGroupChange(res.results[0]);
        })
        .catch(err => console.log(err.response));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    handleAppSortChange = (sortAppColumn, sortAppOrder) => {
        this.setState({ sortAppColumn, sortAppOrder });
        this.updateApps(this.state.selectedGroup, sortAppColumn, sortAppOrder);
    }

    handleContentSortChange = (sortContentColumn, sortContentOrder) => {
        this.setState({ sortContentColumn, sortContentOrder });
        this.updateContentBuilds(this.state.selectedGroup, sortContentColumn, sortContentOrder);
    }

    handleGroupChange = selectedGroup => {
        this.setState({ selectedGroup });
        this.updateApps(selectedGroup, this.state.sortAppColumn, this.state.sortAppOrder);
        this.updateContentBuilds(selectedGroup, this.state.sortContentColumn, this.state.sortContentOrder);
    }

    updateApps = (group, sortAppColumn, sortAppOrder) => {
        getAppsInGroup(this.props.user.accessToken, this.state.selectedCustomer, group.device_group_uuid, sortAppColumn, sortAppOrder)
        .then(res => {
            console.log(res);
            
            const apps = res;
            apps.forEach(app => {
                app.device_group_devices_with_latest_app_version = getAdoption(app.device_group_devices_with_latest_app_version, app.device_group_active_devices);
            });
            this.setState({ apps });
        })
        .catch(err => console.log(err.response));
    }

    updateContentBuilds = (group, sortContentColumn, sortContentOrder) => {
        getContentInGroup(this.props.user.accessToken, this.state.selectedCustomer, group.device_group_uuid, sortContentColumn, sortContentOrder)
        .then(res => {
            let contentBuilds = res;
            contentBuilds = contentBuilds.filter(cb => cb.content_build_version_releases[0].content_build_version_release_state !== "WITHDRAWN");
            contentBuilds.forEach(cb => {
                cb.device_group_devices_with_content_build_version = getAdoption(cb.device_group_devices_with_content_build_version, cb.device_group_active_devices);
                cb.content_build_activation_date = cb.content_build_version_releases[0].content_build_version_release_activated_date;
                cb.content_build_version_deploy_date = cb.content_build_version_releases[0].content_build_version_release_deployed_date;
            });
            this.setState({ contentBuilds });
        })
        .catch(err => console.log(err.response));
    }

    handleWithdrawApplication = app => {
        if(app) {
            withdrawApplicationVersion(this.props.user.accessToken, this.state.selectedCustomer, app.current_application_version_uuid)
            .then(res => {
                let apps = this.state.apps;
                apps = apps.filter(application => application.current_application_version_code !== app.current_application_version_code)
                this.setState({applicationToWitdraw: null, apps});
                console.log(res);
            })
            .catch(err => {
                this.setState({applicationToWitdraw: null});
                console.log(err.response);
            });
        }
    }

    handleWithdrawContentBuild = content => {
        if(content) {
            withdrawContentBuild(this.props.user.accessToken, this.state.selectedCustomer, content.content_build_version_releases[0].content_build_version_release_uuid)
            .then(res => {
                console.log(res);
                if(res.detail === "OK") {
                    let contentBuilds = this.state.contentBuilds;
                    contentBuilds = contentBuilds.filter(cb => cb.content_build_version_releases[0].content_build_version_release_uuid !== content.content_build_version_releases[0].content_build_version_release_uuid)
                    this.setState({contentBuildToWitdraw: null, contentBuilds});
                } 
            })
            .catch(err => {
                this.setState({contentBuildToWitdraw: null});
                console.log(err.response);
            });
        }
    }

    withdrawApps = ids => {
        const applicationToWitdraw = this.state.apps.filter(app => app.application_uuid === ids[0])[0];
        this.setState({ applicationToWitdraw });
    }
    
    withdrawContent = ids => {
        const contentBuildToWitdraw = this.state.contentBuilds.filter(build => build.content_build_uuid === ids[0])[0];
        this.setState({ contentBuildToWitdraw });
    }

    render() {
        const filters = this.state.device_groups && this.state.device_groups.length > 1 ? this.state.device_groups.map((group, index) => {
            return <div key={index} className="filter-item"><Button deviceGroup selected={this.state.selectedGroup === group} onClick={() => this.handleGroupChange(group)}>{group.device_group_name}</Button></div>;
        }): null;
        
        console.log(this.state.selectedGroup);
        

        const activeDevices = this.state.selectedGroup ? <InfoBox title={"Active Devices"} info={this.state.selectedGroup["device_group_active_devices"]}/> : null;
        const appVersion = this.state.selectedGroup ? <InfoBox title={"App & version"} info={this.state.selectedGroup["device_group_primary_app_name"] ? this.state.selectedGroup["device_group_primary_app_name"] + " " + this.state.selectedGroup["device_group_primary_app_latest_version_released"] : "None"}/> : null;
        const contentBuild = this.state.selectedGroup ? <InfoBox title={"Content build"} info={this.state.selectedGroup["device_group_primary_app_content_build_name"] ? this.state.selectedGroup["device_group_primary_app_content_build_name"] : "-"}/> : null;
        const upToDateDevices = this.state.selectedGroup ? <InfoBox title={"Up-to-date devices"} info={this.state.selectedGroup["device_group_up_to_date_devices"]} extra={"of " + this.state.selectedGroup["device_group_active_devices"]}/> : null;

        const appsTable = this.state.apps && this.state.apps.length > 0 ? <Table keyMap="appsVersions" title="Apps & versions" data={this.state.apps} /*onWithdraw={this.withdrawApps}*/ onSortChange={this.handleAppSortChange}/> : null;
        const contentTable = this.state.contentBuilds && this.state.contentBuilds.length > 0 ? <Table keyMap="contentBuildInGroup" title="Content builds" data={this.state.contentBuilds} onWithdraw={this.withdrawContent} onSortChange={this.handleContentSortChange}/> : null;

        let allNull = false;
        if(this.state.selectedGroup) {
            allNull = (!this.state.selectedGroup["device_group_primary_app_name"] && !this.state.selectedGroup["device_group_active_devices"] && !this.state.selectedGroup["device_group_primary_app_content_build_name"] && this.state.selectedGroup["device_group_active_devices"] === 0);
        }
        return (
            <div className="home">
                <h1>Welcome to your dashboard</h1>
                {this.state.contentBuilds && this.state.apps && !appsTable && !contentTable ? <p className="no-dashboard-message">Nothing to show yet.</p> : null}
                <div className="filter-container">
                    {filters}
                </div>
                <div className="group-info-container">
                    {allNull ? null : activeDevices}
                    {allNull ? null : appVersion}
                    {allNull ? null : contentBuild}
                    {allNull ? null : upToDateDevices}
                </div>
                {appsTable}
                {contentTable}
                {this.state.contentBuildToWitdraw ? 
                <Confirmation 
                    title="Withdraw content build version?" 
                    message={"Are you sure you want to withdraw version " + this.state.contentBuildToWitdraw.content_build_version_code + " of " + this.state.contentBuildToWitdraw.content_build_name  + "?"}
                    onCancel={() => this.setState({contentBuildToWitdraw: null})}
                    onConfirm={() => this.handleWithdrawContentBuild(this.state.contentBuildToWitdraw)}
                /> : null}
                {this.state.applicationToWitdraw ? 
                <Confirmation 
                    title="Withdraw application version?" 
                    message={"Are you sure you want to withdraw version " + this.state.applicationToWitdraw.current_application_version_name + " of " + this.state.applicationToWitdraw.application_name + "?"}
                    onCancel={() => this.setState({applicationToWitdraw: null})}
                    onConfirm={() => this.handleWithdrawApplication(this.state.applicationToWitdraw)}
                /> : null}
            </div>
        )
    }
}

export default Home;