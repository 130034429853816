import React, { Component } from 'react';
import './DeviceGroups.scss';
import { getDeviceGroups, deleteDeviceGroup } from '../../../api/Api';
import Table from '../../ui/table/Table';
import Dropdown from '../../ui/dropdown/Dropdown';
import Input from '../../ui/input/Input';
import Pagination from '../../ui/pagination/Pagination';
import Button from '../../ui/button/Button';
import DeviceGroupEditor from './deviceGroupEditor/DeviceGroupEditor';

import { ReactComponent as AddIcon } from '../../../assets/svg/add.svg';
import Confirmation from '../../modal/confirmation/Confirmation';
import { getAdoption } from '../../../util/Converter';

export default class DeviceGroups extends Component {
    
    pageSizeOptions = [
        { uuid: 5, name: "5"},
        { uuid: 10, name: "10"},
        { uuid: 20, name: "20"},
        { uuid: 50, name: "50"},
        { uuid: 100, name: "100"},
    ]

    constructor(props) {
        super(props)
        
        this.state = {
            selectedCustomer: props.selectedCustomer,
            pageSize: 20,
            isEditting: false,
        };
    };
    
    componentDidMount() {
        this.updateDeviceGroups(this.state.sortColumn, this.state.sortOrder, 1, this.state.pageSize);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateDeviceGroups(this.state.sortColumn, this.state.sortOrder, 1, this.state.pageSize);
        }
    }

    updateDeviceGroups = (sortColumn, sortOrder, page, pageSize) => {
        getDeviceGroups(this.props.user.accessToken, this.state.selectedCustomer, sortColumn, sortOrder, page, pageSize)
        .then(res => {
            let device_groups = res.results;
            device_groups.forEach(group => {
                group.device_group_up_to_date_devices = getAdoption(group.device_group_up_to_date_devices, group.device_group_active_devices);
            });

            this.setState({
                device_groups,
                currentPage: parseInt(res.current_page), 
                pageCount: res.max_pages, 
                pageSize: parseInt(res.page_size), 
                totalItems: res.total_items, 
                errorMessage: res.results.length === 0 ? "No devices." : ""
            });
        })
        .catch(err => {
            console.log(err);
            this.setState({errorMessage: "Could not retrieve data from the server. " + (err.response ? "(Server status: " + err.response.status + ")" : "Unknown status")});
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    pageChangeHandler = page => {
        this.updateDeviceGroups(this.state.sortColumn, this.state.sortOrder, page, this.state.pageSize);
    }

    pageSizeChangeHandler = size => {
        if(this.state.pageSize !== size) {
            this.setState({ pageSize: size });
            this.updateDeviceGroups(this.state.sortColumn, this.state.sortOrder, this.state.currentPage ? this.state.currentPage : 1, size);
        }
    }

    editHandler = deviceGroups => {
        const deviceGroupToEdit = this.state.device_groups.filter(group => {return group.device_group_uuid === deviceGroups})[0];
        this.setState({isEditting: true, groupToEdit: deviceGroupToEdit});
    }

    addHandler = () => {
        this.setState({isEditting: true, groupToEdit: null});
    }

    editFinishHandler = uuid => {
        this.setState({isEditting: false, groupToEdit: this.state.groupToEdit});
        if(uuid) {
            window.location.reload();
        }
    }

    handleSortChange = (sortColumn, sortOrder) => {
        this.setState({ sortColumn, sortOrder });
        this.updateDeviceGroups(sortColumn, sortOrder, this.state.currentPage ? this.state.currentPage : 1, this.state.pageSize);
    }

    deleteHandler = deviceGroups => {
        const groupToRemove = this.state.device_groups.filter(group => group.device_group_uuid === deviceGroups[0])[0];
        this.setState({groupToRemove});
    }

    removeDeviceGroup = deviceGroup => {
        let device_groups = this.state.device_groups;
        device_groups = this.state.device_groups.filter(group => group.device_group_uuid !== deviceGroup.device_group_uuid);
        deleteDeviceGroup(this.props.user.accessToken, this.state.selectedCustomer, deviceGroup.device_group_uuid)
        .then(res => {
            this.setState({groupToRemove: null, device_groups});
        })
        .catch(err => {
            console.log(err.response);
        })
    }

    render() {
    const devicesTable = this.state.device_groups && this.state.device_groups.length > 0 ? <Table keyMap="deviceGroups" data={this.state.device_groups} onRemove={this.deleteHandler} onEdit={this.editHandler} onSortChange={this.handleSortChange}/> : null;
        const pagination = this.state.currentPage ? <Pagination 
            className="pagination"
            items="device groups"
            currentPage={this.state.currentPage}
            pageCount={this.state.pageCount}
            totalItems={this.state.totalItems}
            pageSize={this.state.pageSize}
            onPageChange={this.pageChangeHandler}
        />: null;
        return (
            this.state.isEditting ?
            <DeviceGroupEditor user={this.props.user} deviceGroup={this.state.groupToEdit} customer={this.state.selectedCustomer} onFinish={this.editFinishHandler}/>
            :
            <div className="device-groups">
                <div className="tab-header">
                    <h1>Device Groups</h1>
                    <Dropdown className="number-dropdown" options={this.pageSizeOptions} selectedValue={this.state.pageSize} onSelect={this.pageSizeChangeHandler}/>
                </div>
                <div className="device-groups-top-bar">
                    <Input className="device-group-search" placeholder="Search..." search/>
                    <Button className="device-group-add-button" icon={<AddIcon/>} onClick={() => this.addHandler()}>Add new device group</Button>
                </div>
                {devicesTable}
                {devicesTable ? pagination : this.state.device_groups && this.state.device_groups.length > 0 ? null : <p className="no-device-group-text">{this.state.errorMessage}</p>}
                {this.state.groupToRemove ? 
                <Confirmation 
                    title="Remove device group?" 
                    message={"Are you sure you want to remove " + this.state.groupToRemove.device_group_name + "?"}
                    onCancel={() => this.setState({groupToRemove: null})}
                    onConfirm={() => this.removeDeviceGroup(this.state.groupToRemove)}
                /> : null}
            </div>
        )
    }
}
