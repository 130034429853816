import React, { Component } from 'react';
import './Devices.scss';
import { getDevices } from '../../../api/Api';
import Table from '../../ui/table/Table';
import Dropdown from '../../ui/dropdown/Dropdown';
import Input from '../../ui/input/Input';
import Pagination from '../../ui/pagination/Pagination';

class Devices extends Component {

    actions = [
        { uuid: "header", name: "Actions" },
        { uuid: 1, name: "Delete" },
        { uuid: 2, name: "Other action" },
    ]

    pageSizeOptions = [
        { uuid: 5, name: "5"},
        { uuid: 10, name: "10"},
        { uuid: 20, name: "20"},
        { uuid: 50, name: "50"},
        { uuid: 100, name: "100"},
    ]

    constructor(props) {
        super(props)
        
        this.state = {
            selectedCustomer: props.selectedCustomer,
            pageSize: 20,
        };
    };
    
    componentDidMount() {
        this.updateDevices(null, null, 1, this.state.pageSize);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateDevices(null, null, 1, this.state.pageSize);
        }
    }

    updateDevices = (sortColumn, sortOrder, page, pageSize) => {
        getDevices(this.props.user.accessToken, this.state.selectedCustomer, sortColumn, sortOrder, page, pageSize)
        .then(res => {
            console.log(res.results);
            this.setState({
                devices: res.results, 
                currentPage: parseInt(res.current_page), 
                pageCount: res.max_pages, 
                pageSize: parseInt(res.page_size), 
                totalItems: res.total_items, 
            });
        })
        .catch(err => console.log(err.response.status));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    // onActionSelected = action => {
    //     console.log(action);
    // }

    pageChangeHandler = page => {
        this.updateDevices(this.state.sortColumn, this.state.sortOrder, page, this.state.pageSize);
    }

    pageSizeChangeHandler = size => {
        if(this.state.pageSize !== size) {
            this.setState({ pageSize: size });
            this.updateDevices(this.state.sortColumn, this.state.sortOrder, this.state.currentPage ? this.state.currentPage : 1, size);
        }
    }

    handleSortChange = (sortColumn, sortOrder) => {
        this.setState({ sortColumn, sortOrder });
        this.updateDevices(sortColumn, sortOrder, this.state.currentPage ? this.state.currentPage : 1, this.state.pageSize);
    }

    render() {
        const devicesTable = this.state.devices && this.state.devices.length > 0 ? <Table keyMap="devices" data={this.state.devices} onSortChange={this.handleSortChange}/> : null;
        const pagination = this.state.currentPage ? <Pagination 
            className="pagination"
            items="devices"
            currentPage={this.state.currentPage}
            pageCount={this.state.pageCount}
            totalItems={this.state.totalItems}
            pageSize={this.state.pageSize}
            onPageChange={this.pageChangeHandler}
        />: null;
        return (
            <div className="devices">
                <div className="tab-header">
                    <h1>Devices</h1>
                    <Dropdown className="number-dropdown" options={this.pageSizeOptions} selectedValue={this.state.pageSize} onSelect={this.pageSizeChangeHandler}/>
                </div>
                <div className="devices-top-bar">
                    {/*<Dropdown className="device-actions" menu options={this.actions} onSelect={this.onActionSelected} selectedValue={this.actions[0].uuid}/>*/}
                    <Input className="device-search" placeholder="Search..." search/>
                </div>
                {devicesTable}
                {devicesTable ? pagination : this.state.devices && this.state.devices.length > 0 ? null : <p className="no-device-text">No devices.</p>}
            </div>
        )
    }
}

export default Devices;