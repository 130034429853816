import React, { Component } from 'react'
import './Apps.scss';
import { getApps } from '../../../api/Api';
import Table from '../../ui/table/Table';
import Dropdown from '../../ui/dropdown/Dropdown';
import Input from '../../ui/input/Input';
import Pagination from '../../ui/pagination/Pagination';
import Button from '../../ui/button/Button';
import { ReactComponent as AddIcon } from '../../../assets/svg/add.svg';
import AppsEditor from './appsEditor/AppsEditor';
import Confirmation from '../../modal/confirmation/Confirmation';

export default class Apps extends Component {

    pageSizeOptions = [
        { uuid: 5, name: "5"},
        { uuid: 10, name: "10"},
        { uuid: 20, name: "25"},
        { uuid: 50, name: "50"},
        { uuid: 100, name: "100"},
    ]

    constructor(props) {
        super(props)
        
        this.state = {
            selectedCustomer: props.selectedCustomer,
            pageSize: 20,
            isEditting: false,
        };
    };
    
    componentDidMount() {
        this.updateApps(1, this.state.pageSize);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateApps(1, this.state.pageSize);
        }
    }

    updateApps = (page, pageSize) => {
        getApps(this.props.user.accessToken, this.state.selectedCustomer, this.state.sortColumn, this.state.sortOrder, page, pageSize)
        .then(res => {
            this.setState({
                apps: res.results, 
                currentPage: parseInt(res.current_page), 
                pageCount: res.max_pages, 
                pageSize: parseInt(res.page_size), 
                totalItems: res.total_items, 
            });
        })
        .catch(err => console.log(err.response));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    pageChangeHandler = page => {
        this.updateApps(page, this.state.pageSize);
    }

    pageSizeChangeHandler = size => {
        if(this.state.pageSize !== size) {
            this.setState({ pageSize: size });
            this.updateApps(this.state.currentPage ? this.state.currentPage : 1, size);
        }
    }

    deleteHandler = ids => {
        const appToRemove = this.state.apps.filter(app => app.application_uuid === ids[0])[0];
        this.setState({ appToRemove });
    }

    // removeApp = app => {
    //     this.setState({appToRemove: null});
    // }

    editHandler = ids => {
        this.toggleAppEditor(true, ids);
    }

    editFinishHandler = uuid => {
        this.setState({isEditting: false});
        if(uuid) {
            window.location.reload();
        }
    }

    handleSortChange = (sortColumn, sortOrder) => {
        this.setState({ sortColumn, sortOrder });
        this.updateApps(this.state.currentPage ? this.state.currentPage : 1, this.state.pageSize);
    }

    toggleAppEditor = (isEditting, appToEdit) => {
        this.setState({ isEditting, appToEdit });
    }

    render() {
        const devicesTable = this.state.apps && this.state.apps.length > 0 ? <Table keyMap="apps" data={this.state.apps} onEdit={this.editHandler} onSortChange={this.handleSortChange}/> : null;
        const pagination = this.state.currentPage ? <Pagination 
            className="pagination"
            items="apps"
            currentPage={this.state.currentPage}
            pageCount={this.state.pageCount}
            totalItems={this.state.totalItems}
            pageSize={this.state.pageSize}
            onPageChange={this.pageChangeHandler}
        />: null;
        return (
            this.state.isEditting ? <AppsEditor user={this.props.user} customer={this.state.selectedCustomer} app={this.state.appToEdit} onFinish={this.editFinishHandler}/> :
            <div className="apps">
                <div className="tab-header">
                    <h1>Apps</h1>
                    <Dropdown className="number-dropdown" options={this.pageSizeOptions} selectedValue={this.state.pageSize} onSelect={this.pageSizeChangeHandler}/>
                </div>
                <div className="apps-top-bar">
                    <Input className="apps-search" placeholder="Search..." search/>
                    <Button className="apps-add-button" icon={<AddIcon/>} onClick={() => this.toggleAppEditor(true)}>Add new app</Button>
                </div>
                {devicesTable}
                {devicesTable ? pagination : this.state.apps && this.state.apps.length > 0 ? null : <p className="no-apps-text">No apps.</p>}
                {this.state.appToRemove ? 
                <Confirmation 
                    title="Remove application?" 
                    message={"Are you sure you want to remove " + this.state.appToRemove.application_name + "?"}
                    onCancel={() => this.setState({appToRemove: null})}
                    onConfirm={() => this.removeApp(this.state.appToRemove)}
                /> : null}
            </div>
        )
    }
}
