import React, { Component } from 'react';
import DatePicker from 'react-datepicker'
import { ReactComponent as DateIcon } from "../../../assets/svg/date.svg";
import { ReactComponent as TimeIcon } from "../../../assets/svg/time.svg";
import "./DateTimePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

export default class DateTimePicker extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            
        };
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.defaultValue || nextProps.minDate) {
            let state = prevState;
            state.date = new Date(nextProps.defaultValue * 1000);
            state.time = new Date(nextProps.defaultValue * 1000);
            state.minDate = nextProps.minDate
            return state;
        }
        return prevState;
    }
    
    dateChanged = date => {
        this.setState({date});
        this.props.onChange(new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.state.time ? this.state.time.getHours() : 0, this.state.time ? this.state.time.getMinutes() : 0 ));
    }

    timeChanged = time => {
        this.setState({time});
        this.props.onChange(new Date(this.state.date ? this.state.date.getFullYear() : 1970, this.state.date ? this.state.date.getMonth() : 1, this.state.date ? this.state.date.getDate() : 1, time.getHours(), time.getMinutes()));
    }
    
    render() {
        return (
            <div className="datetime-picker">
                <h6>{this.props.title}</h6>
                <div className="datetime-picker-holder">
                    <DatePicker
                        readOnly={this.props.readOnly}
                        selected={this.state.date}
                        onChange={this.dateChanged}
                        minDate={this.state.minDate}
                        dateFormat="d MMM yyyy"
                    />
                    <DatePicker
                        readOnly={this.props.readOnly}
                        selected={this.state.time}
                        onChange={this.timeChanged}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                    />
                    <DateIcon/>
                    <TimeIcon/>
                </div>
            </div>
        )
    }
}
