import React, { Component } from 'react';
import './AppAdder.scss';
import Modal from '../../../../modal/Modal';
import Button from '../../../../ui/button/Button';
import Dropdown from '../../../../ui/dropdown/Dropdown';

import { getApps, getAppVersions } from '../../../../../api/Api';
import onClickOutside from "react-onclickoutside";
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg';

class AppAdder extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            selectedApp: null,
            selectedVersion: null,
        }
    }

    handleClickOutside(){
        this.props.onClose();
    }
    
    componentDidMount() {
        getApps(this.props.user.accessToken, this.props.customer)
        .then(res => {
            const appOptions = res.results.map(app => {
                return {uuid: app.application_uuid, name: app.application_name}
            });
            this.setState({apps: res.results});
            this.setState({appOptions: appOptions});
        })
    }

    onAppSelected = application => {
        if(application != null && this.state.selectedApp !== application) {
            const app = this.state.apps.filter(item => {
                return item.application_uuid === application;
            })
            const newApplication = {
                application_uuid: app[0].application_uuid,
                application_name: app[0].application_name,
                current_application_version_code: app[0].application_latest_version_code ? app[0].application_latest_version_code : 1,
                current_application_version_name: app[0].application_latest_version_name,
                current_application_version_uuid: app[0].application_latest_version_uuid,
                previous_application_versions: [],
                device_group_active_devices: 0,
                device_group_devices_with_latest_app_version: 0
            }
            this.setState({selectedApp: newApplication, versions: null, selectedVersion: null});
            getAppVersions(this.props.user.accessToken, this.props.customer, application)
            .then(res => {
                const versionOptions = res.versions.map(version => {
                    return { uuid: version.application_version_uuid, name: version.application_version_name }
                })
                this.setState({versions: res.versions, versionOptions: versionOptions});
            })
        }
    }

    onVersionSelected = appVersion => {
        const version = this.state.versions.filter(version => {
            return version.application_version_uuid === appVersion;
        })
        let selectedApp = this.state.selectedApp;
        selectedApp.current_application_version_code = version[0].application_version_code;
        selectedApp.current_application_version_name = version[0].application_version_name;
        selectedApp.current_application_version_uuid = version[0].application_version_uuid;
        this.setState({selectedApp: selectedApp, selectedVersion: version});
    }

    render() {
        return (
            <Modal >
                <div className="app-adder">
                    <h1>Add app</h1>
                    <CloseIcon className="close" onClick={() => this.props.onClose()}/>
                    <Dropdown title="App" placeholder="Select app" selectedValue={this.state.selectedApp ? this.state.selectedApp.application_uuid : null} options={this.state.appOptions} onSelect={this.onAppSelected} />
                    <Dropdown title="Version" placeholder="Select version" selectedValue={this.state.selectedVersion} options={this.state.versionOptions} onSelect={this.onVersionSelected}/>
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button onClick={() => this.props.onSave(this.state.selectedApp, this.state.selectedVersion)} disabled={!this.state.selectedApp || !this.state.selectedVersion}>Save</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default onClickOutside(AppAdder);
