import React, { Component } from 'react';
import './AppVersionAdder.scss';
import Modal from '../../../../modal/Modal';
import Button from '../../../../ui/button/Button';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg';
import Textarea from '../../../../ui/textarea/Textarea';
import FileUpload from '../../../../ui/fileUpload/FileUpload';
import Input from '../../../../ui/input/Input';
import { verifyApplicationVersion } from '../../../../../api/Verifier';

export default class AppVersionAdder extends Component {

    constructor(props) {
        super(props)

        this.state = {
            apkFile: null,
            params: {
                edit_id: props.versionToEdit ? props.versionToEdit.edit_id : null,
                uuid: props.versionToEdit ? props.versionToEdit.uuid : null,
                change_log: props.versionToEdit ? props.versionToEdit.changeLog : null,
                version_name: props.versionToEdit ? props.versionToEdit.versionName : null,
                version_code: props.versionToEdit ? props.versionToEdit.versionCode : null,
                min_sdk: props.versionToEdit ? props.versionToEdit.minSdk : null,
                file_size: props.versionToEdit ? props.versionToEdit.fileSize : 0,
                file_link: props.versionToEdit ? props.versionToEdit.fileLink : null,
            },
        };

        this.handleVersionNameChange.bind(this);
        this.handleVersionCodeChange.bind(this);
        this.handleMinSdkChange.bind(this);
        this.handleChangelogChange.bind(this);
    };

    saveAppVersion = () => {
        this.props.onClose(this.state.params, this.state.apkFile);
    }

    handleApk = apkFile => {
        this.setState({apkFile: apkFile[0]});
    }

    handleVersionNameChange = event => {
        let params = this.state.params;
        params.version_name = event.target.value;
        this.setState({ params });
    }

    handleVersionCodeChange = event => {
        let params = this.state.params;
        params.version_code = event.target.value;
        this.setState({ params });
    }

    handleMinSdkChange = event => {
        let params = this.state.params;
        params.min_sdk = event.target.value;
        this.setState({ params });
    }

    handleChangelogChange = event => {
        let params = this.state.params;
        params.change_log = event.target.value;
        this.setState({ params });
    }
    
    render() {
        return (
            <Modal>
                <div className="app-version-adder">
                    <h1>{this.props.changeLogOnly ? "Changelog" : "Upload new version"}</h1>
                    <CloseIcon className="close" onClick={() => this.props.onClose()}/>
                    {!this.props.changeLogOnly ? <div><FileUpload
                        apkOnly
                        handleDrop={this.handleApk}
                    />
                    <Input
                        title="Version name"
                        defaultValue={this.state.params.version_name}
                        onChange={this.handleVersionNameChange}
                    />
                    <Input
                        title="Version code"
                        defaultValue={this.state.params.version_code}
                        onChange={this.handleVersionCodeChange}
                    />
                    <Input
                        title="Minimum SDK"
                        defaultValue={this.state.params.min_sdk}
                        onChange={this.handleMinSdkChange}
                    />
                    <Textarea 
                        title="Changelog description" 
                        placeholder="Fixed this and that..."
                        defaultValue={this.state.params.change_log}
                        onChange={this.handleChangelogChange}
                    />
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button onClick={() => this.saveAppVersion()} disabled={!verifyApplicationVersion(this.props.versionToEdit ? this.props.versionToEdit : this.state.apkFile, this.state.params.version_name, this.state.params.version_code, this.state.params.min_sdk)}>Save</Button>
                    </div>
                    </div>: 
                    <div><Textarea 
                        title="Description" 
                        readOnly
                        defaultValue={this.props.changeLog}
                    />
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Close</Button>
                    </div>
                    </div>}
                    
                </div>
            </Modal>
        )
    }
}
