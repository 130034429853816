export const convertFileSize = filesize => {
    let size = "";
    if(!filesize) {
        return "<1 kB";
    }
    if(filesize >= 1000000000) {
        size = round(filesize * 0.000000001, 1) + " GB";
    }
    else if(filesize >= 1000000) {
        size = round(filesize * 0.000001, 1) + " MB";
    }
    else if(filesize >= 1000) {
        size = round(filesize * 0.001, 1) + " kB";
    }
    else {
        size = filesize + " B";
    }
    return size;
}

export const convertAndroidSDK = sdkVersion => {
    sdkVersion = parseInt(sdkVersion);
    switch (sdkVersion) {
        case 19:
            return "4.4 (KitKat)"
        case 20:
            return "4.4W (KitKat)"
        case 21:
            return "5.0 (Marshmallow)"
        case 22:
            return "5.1 (Lollipop)"
        case 23:
            return "6.0 (Lollipop)"
        case 24:
            return "7.0 (Nougat)"
        case 25:
            return "7.1 (Nougat)"
        case 26:
            return "8.0 (Oreo)"
        case 27:
            return "8.1 (Oreo)"
        case 28:
            return "9.0 (Pie)"
        case 29:
            return "Android 10"
        default:
            return "Undefined";
    }
}

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const getAdoption = (amount, total) => {
    if(total !== 0) {
        return amount + "/" + total + " (" + round(amount / total * 100, 1) + "%)";
    }
    else {
        return amount + "/" + total + " (0%)";
    }
}
