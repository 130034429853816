import React, { Component } from 'react'
import Axios from 'axios';

import Login from './login/Login';
import ForgetPassword from './forgotPassword/ForgetPassword';
import PasswordRecovery from './passwordRecovery/PasswordRecovery';

import './Authentication.scss';

export default class Authentication extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            activeScreen: "login",
            loginError: "",
            isLoadingLogin: false,
            isLoadingEmailRecovery: false,
            emailRecoveryStatus: {success: false, message: ""},
            passwordResetStatus: {success: false, message: ""},
        };
    };
    
    loginHandler = (email, password) => {
        this.setState({ isLoadingLogin: true });
        const body = {
            email: email, 
            password: password,
        }
        Axios.post('/auth/jwt/create', body)
        .then(res => {
            this.setState({ loginError: "", isLoadingLogin: false });
            if(res.data) {
                this.props.onLoggedIn(res.data);
            }
        })
        .catch(err => {
            let errorMessage = "Couldn't connect to the server.=";
            if(err.response) {
                if(err.response.status === 401) {
                    errorMessage = "The email/password combination does not exist";
                }
                if(err.response.status === 500) {
                    errorMessage = "Server error";
                }
            }
            this.setState({ loginError: errorMessage, isLoadingLogin: false });
        })
    }

    forgotHandler = email => {
        this.setState({ isLoadingEmailRecovery: true });
        Axios.post('/auth/password/reset/', { email: email })
        .then(res => {
            const status = {success: true, message: "Provided that the email specified is linked to an account, you will receive a reset-link"};
            this.setState({emailRecoveryStatus: status, isLoadingEmailRecovery: false});
        })
        .catch(err => {
            console.log(err.response);
            const status = {success: false, message: "Internal server error, please try again later"};
            this.setState({emailRecoveryStatus: status, isLoadingEmailRecovery: false});
        })
    }

    recoverHandler = (uid, token, password) => {
        this.setState({isLoadingPasswordReset: true});
        let status = {success: false, message: "Failed to change password."};
        Axios.post('/auth/password/reset/confirm', {uid: uid, token: token, password})
        .then(res => {
            status = {success: true, message: "You are transferred to the login page."};
            this.setState({isLoadingPasswordReset: false, passwordResetStatus: status});
            this.sleep(1000).then(() => {
                window.location = window.location.origin;
            });
        })
        .catch(err => {
            let messages = [];
            if(err.response) {
                if(err.response.status === 400) {
                    Object.keys(err.response.data).forEach(key => {
                        messages = messages.concat(err.response.data[key]);
                    });

                    let message = messages.join("\n \u2022");
                    if(messages.length > 1) {
                        message = "\u2022 " + message;
                    }
                    status = {success: false, message: message};
                }
                if(err.response.status === 500) {
                    status = {success: false, message: "Failed to send the mail"};
                }
            }
            this.setState({isLoadingPasswordReset: false, passwordResetStatus: status});
        })
    }

    goBackHandler = () => {
        this.setState({activeScreen: "login"});
    }

    forgotClickHandler = () => {
        this.setState({activeScreen: "forgotPassword"});
    }

    render() {
        return (
            <div>
                {this.state.activeScreen === "login" ?  <Login onSubmit={this.loginHandler} onForgot={this.forgotClickHandler} loginError={this.state.loginError} loading={this.state.isLoadingLogin}/> : null}
                {this.state.activeScreen === "forgotPassword" ? <ForgetPassword onSubmit={this.forgotHandler} onGoBack={this.goBackHandler} status={this.state.emailRecoveryStatus} loading={this.state.isLoadingEmailRecovery}/> : null}
                {this.state.activeScreen === "recover" ? <PasswordRecovery onSubmit={this.recoverHandler} status={this.state.passwordResetStatus} loading={this.state.isLoadingPasswordReset}/> : null}
            </div>
        )
    }
}
