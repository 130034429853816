import React from 'react';
import './Textarea.scss';

export default function Textarea(props) {
    return (
        <div className="text-area">
            <p>{props.title}</p>
            <textarea 
                readOnly={props.readOnly} 
                defaultValue={props.defaultValue} 
                onChange={props.onChange}
                placeholder={props.placeholder}
            />
        </div>
    )
}
