import React, { Component, Fragment } from 'react'
import { getContentBuilds, deleteContentBuild } from '../../../api/Api'
import './ContentBuilds.scss'
import Input from '../../ui/input/Input'
import Button from '../../ui/button/Button'
import { ReactComponent as AddIcon } from '../../../assets/svg/add.svg';
import Table from '../../ui/table/Table'
import Pagination from '../../ui/pagination/Pagination'
import ContentBuildEditor from './contentBuildEditor/ContentBuildEditor'
import { exportBuild } from "../../../api/Api";
import Notification from '../../modal/notification/Notification';
import Loader from '../../ui/loader/Loader'

export default class ContentBuilds extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isEditting: false,
            alreadyReleased: false,
            contentBuildToEdit: null,
            selectedCustomer: props.selectedCustomer,
            showExportRunningModal: false,
            showExportFailedModal: false,
            showExportFinishedModal: false,
            showExporterRunningIndicatior: false,
        }
    }

    componentDidMount() {
        this.updateContentBuilds();
    }

    updateContentBuilds = () => {
        getContentBuilds(this.props.user.accessToken, this.state.selectedCustomer, "PREPARATION")
        .then(res => {
            this.setState({preparation: res.results});
        })
        .catch(err => {
            console.log(err.response);
        });
        getContentBuilds(this.props.user.accessToken, this.state.selectedCustomer, "RELEASED")
        .then(res => {
            this.setState({released: res.results});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateContentBuilds();
        }
    }
 
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    toggleContentBuildEditor = (isEditting, contentBuildToEdit, alreadyReleased) => {
        this.setState({ isEditting, contentBuildToEdit, alreadyReleased });
    }

    editHandler = uuid => {
        this.toggleContentBuildEditor(true, uuid, false);
    }

    editReleasedHandler = uuid => {
        this.toggleContentBuildEditor(true, uuid, true);
    }

    handleNewContentBuildClick = async () => {
        const customers = JSON.parse(localStorage.getItem("customers"));
        const customer = customers.find((customer) => customer.uuid === this.props.selectedCustomer);
        const slug = customer.name.toLowerCase().replaceAll(" ", "");

        switch (slug) {
          case "mim":
          case "villamondriaan":    
              this.setState({showExporterRunningIndicatior: true});
              const status = await exportBuild(slug);
              switch(status){
                  case "export started":
                    this.setState({showExportRunningModal: true});
                    break;
                  case "export failed":
                    this.setState({showExportFailedModal: true});
                    break;
                  case "export finished": 
                    this.setState({showExportFinishedModal: true});
                    break;
                    default:break;
              }  
              this.setState({showExporterRunningIndicatior: false});
            break;
          default:
            this.toggleContentBuildEditor(true);
        }
      };

    removeHandler = uuid => {
        deleteContentBuild(this.props.user.accessToken, this.state.selectedCustomer, uuid[0])
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err.response);
        })
    }

    editFinishHandler = uuid => {
        this.setState({isEditting: false});
        if(uuid) {
            window.location.reload();
        }
    }

    render() {
        const preparationTable = this.state.preparation && this.state.preparation.length > 0 ? <Table title="In preparation" keyMap="contentBuild" data={this.state.preparation} onEdit={this.editHandler} /*onRemove={this.removeHandler}*//> : null;
        const preparationPagination = this.state.preparation ? <Pagination 
            className="pagination"
            items="content builds in preparation"
            currentPage={1}
            pageCount={1}
            totalItems={this.state.preparation.length}
            pageSize={this.state.preparation.length}
        />: null;
        const releasedTable = this.state.released && this.state.released.length > 0 ? <Table title="Released" keyMap="contentBuild" data={this.state.released} onView={this.editReleasedHandler}/> : null;
        const releasedPagination = this.state.released ? <Pagination 
            className="pagination"
            items="content builds released"
            currentPage={1}
            pageCount={1}
            totalItems={this.state.released.length}
            pageSize={this.state.released.length}
        />: null;

        return (
            <Fragment>
                {this.state.showExportFinishedModal ? <Notification title={"Finished"} message={"The export has finshed."} onConfirm={() => this.setState({showExportFinishedModal: false})}/> : null}
                {this.state.showExportRunningModal ? <Notification title={"Running"} message={"The export is running. This process can take a few minutes."} onConfirm={() => this.setState({showExportRunningModal: false})}/> : null}
                {this.state.showExportFailedModal ? <Notification title={"Failed"} message={"The export has failed. This happens when an other export is still running."} onConfirm={() => this.setState({showExportFailedModal: false})}/> : null}
                {this.state.isEditting ? <ContentBuildEditor user={this.props.user} customer={this.state.selectedCustomer} contentBuildToEdit={this.state.contentBuildToEdit} onFinish={this.editFinishHandler} alreadyReleased={this.state.alreadyReleased}/> :
                <div className="content-builds">
                    <div className="tab-header">
                        <h1>Content builds</h1>
                    </div>
                    <div className="content-builds-top-bar">
                        <Input className="content-builds-search" placeholder="Search..." search/>
                        <Button className="content-builds-add-button" icon={this.state.showExporterRunningIndicatior? (<Loader inButton={true} />): (<AddIcon />)} onClick={this.handleNewContentBuildClick}>
                            Add new content build
                        </Button>
                    </div>
                    <div>
                        {preparationTable}
                        {preparationTable ? preparationPagination : this.state.preparation && this.state.preparation.length > 0 ? null : <p className="no-content-builds-text">No content build in preparation.</p>}
                        {releasedTable}
                        {releasedTable ? releasedPagination : this.state.released && this.state.released.length > 0 ? null : <p className="no-content-builds-text">No content builds released.</p>}
                    </div>
                </div>}
            </Fragment>
        )
    }
}
