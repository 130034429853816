import React, { Component } from 'react';
import "./ReleaseAdder.scss";
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg';
import DateTimePicker from '../../../../ui/dateTimePicker/DateTimePicker';
import Dropdown from '../../../../ui/dropdown/Dropdown';
import Button from '../../../../ui/button/Button';
import Modal from '../../../../modal/Modal';
import { getDeviceGroups, createRelease } from '../../../../../api/Api';
import moment from 'moment';

export default class ReleaseAdder extends Component {

    constructor(props) {
        super(props)

        this.state = {
            options: [],
            params: {
                content_build_version_uuid: props.contentBuild,
                device_group_uuid: null,
                activation_timestamp: props.availableTimestamp,
                download_timestamp: props.availableTimestamp,
            }
        };
    };

    componentDidMount() {
        getDeviceGroups(this.props.user.accessToken, this.props.customer)
        .then(res => {
            console.log(res.results);
            const options = res.results.map(group => {
                return {
                    uuid: group.device_group_uuid,
                    name: group.device_group_name
                }
            });
            this.setState({ deviceGroups: res.results, options });
        })
    }

    save = () => {
        console.log(this.state.params);
        createRelease(this.props.user.accessToken, this.props.customer, this.state.params.content_build_version_uuid, this.state.params.device_group_uuid, {
            activate_timestamp: this.state.params.activation_timestamp,
            deploy_timestamp : this.state.params.download_timestamp,
        })
        .then(res => {
            console.log(res);
            
            const release = {
                activation_date: this.props.availableTimestamp,
                deploy_date: this.state.params.download_timestamp,
                device_group: this.state.device_group.device_group_color + " " + this.state.device_group.device_group_name,
                adoption: 0 + "/" + this.state.device_group_active_devices,
            }
            this.props.onClose(release)
        })
        .catch(err => {
            console.log(err.response);
        })
    }

    onSelect = uuid => {
        let params = this.state.params;
        params.device_group_uuid = uuid;
        const device_group = this.state.deviceGroups.find(group => group.device_group_uuid === uuid);
        const device_group_active_devices = this.state.deviceGroups.find(group => group.device_group_uuid === uuid).device_group_active_devices;
        this.setState({ params, device_group, device_group_active_devices });
    }

    onChangeDownload = date => {
        let params = this.state.params;
        params.download_timestamp = Date.parse(date) * 0.001;
        this.setState({ params });
    }

    onChangeAvailable = date => {
        let params = this.state.params;
        params.activation_timestamp = Date.parse(date) * 0.001;
        this.setState({ params });
    }

    render() {
        return (
            <Modal>
                <div className="release-adder">
                    <h1>{this.props.title}</h1>
                    <CloseIcon className="close" onClick={() => this.props.onClose()}/>
                    <div className="release-holder">
                        <DateTimePicker 
                            title="Available for download on"
                            onChange={this.onChangeDownload}
                            minDate={moment().utc().unix() * 1000}
                            defaultValue={this.state.params.download_timestamp}
                        />
                        <DateTimePicker 
                            title="Activate release on"
                            onChange={this.onChangeAvailable}
                            minDate={this.state.params.download_timestamp * 1000}
                            defaultValue={this.state.params.download_timestamp > this.state.params.activation_timestamp ? this.state.params.download_timestamp : this.state.params.activation_timestamp}
                        />
                        <Dropdown
                            title="Select device group"
                            placeholder="Device group"
                            options={this.state.options}
                            onSelect={this.onSelect}
                        />
                    </div>
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button onClick={() => this.save()}>Release</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
