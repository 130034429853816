import React from 'react';
import './InfoBox.scss';

export default function InfoBox(props) {
    return (
        <div className="info-box">
            <div className="info-box-info"> 
                <p>{props.info}</p>
                <p className="info-box-extra">{props.extra}</p>
            </div>
            <h1 className="info-box-title">{props.title}</h1>
        </div>
    )
}
