import React, { Component } from 'react';
import "./ProgressBar.scss";

export default class ProgressBar extends Component {
    render() {
        return (
            <div className="progress-bar">
                <div className="progress-holder">
                    <div/>
                    <div style={{width: 74 * ((this.props.progress ? this.props.progress : 0) / (this.props.total ? this.props.total : 1))}}/>
                </div>
            </div>
        )
    }
}
