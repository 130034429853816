import React, { Component } from 'react'
import classNames from 'classnames';

import './Dropdown.scss';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '../../../assets/svg/dd-arrow.svg';
import onClickOutside from "react-onclickoutside";

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            selectedItem: props.options ? props.options.find(item => {return item.uuid === props.selectedValue}) : null,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.options && prevState.selectedItem) {
            let newState = prevState;
            const options = nextProps.options.filter(option => {
                return option.uuid === prevState.selectedItem.uuid;
            });
            if(options.length === 0) {
                newState.selectedItem = null;
                return newState;
            }
        }
        else if (nextProps.options && nextProps.selectedValue) {
            let newState = prevState;
            newState.selectedItem = nextProps.options.find(item => item.uuid === nextProps.selectedValue);
            return newState;
        }
        return prevState;
    }

    handleClickOutside(){
        this.setState({
            listOpen: false
        })
    }

    toggleList(){
        if(!this.props.readOnly) {
            this.setState(prevState => ({
                listOpen: !prevState.listOpen
            }))
        }
    }

    handleSelect = item => {
        this.setState({
            selectedItem: this.props.menu ? this.state.selectedItem : item,
            listOpen: false,
        })
        this.props.onSelect(item.uuid);
    }

    render() {
        const {options} = this.props
        const {listOpen, selectedItem} = this.state
        const filteredOptions = selectedItem && options ? options.filter(item => {
            return item.uuid !== selectedItem.uuid;
        }) : options;

        return (
            <div className="dropdown">
                {this.props.title ? <p>{this.props.title}</p> : null}
                <div className={classNames('dd-wrapper', this.props.className, { 'dd-wrapper-open': listOpen })}>
                    <div className="dd-header" onClick={() => this.toggleList()}>
                        <div className={classNames("dd-header-title", {"dd-placeholder" : this.props.placeholder && !selectedItem})}>{selectedItem ? selectedItem.name : this.props.placeholder}</div>
                        {this.props.readOnly ? null : <span><Arrow className={classNames("dd-arrow", {up: this.state.listOpen})}/></span>}
                    </div>
                    {options ? listOpen && <ul className="dd-list">
                        {this.props.filterSelected?  filteredOptions.map((item) => (
                            <li className="dd-list-item" key={item.uuid} onClick={() => this.handleSelect(item)}>{item.name}</li>
                        )): 
                        options.map((item) => (
                            <li className="dd-list-item" key={item.uuid} onClick={() => this.handleSelect(item)}>{item.name}</li>
                        ))}
                    </ul> : null}
                </div>
            </div>
        )
    }
}

Dropdown.defaultProps = {
    onSelect: value => console.log(value)
}

Dropdown.propTypes = {
    options: PropTypes.array,
    onSelect: PropTypes.func,
    filterSelected: PropTypes.bool,
    readOnly: PropTypes.bool,
    selectedValue: PropTypes.any,
}

export default onClickOutside(Dropdown);