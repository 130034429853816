import React, { Component } from 'react';
import './AppsEditor.scss';
import Button from '../../../ui/button/Button';
import Switch from '../../../ui/switch/Switch';
import Input from '../../../ui/input/Input';
import Table from '../../../ui/table/Table';
import { getAppVersions, createApplication, createApplicationVersion, updateApplicationVersion } from '../../../../api/Api';
import AppVersionAdder from './appVersionAdder/AppVersionAdder';
import { verifyApplication } from '../../../../api/Verifier';

export default class AppsEditor extends Component {

    currentEditId = 1;
    uploadCount = 0;

    constructor(props) {
        super(props)
    
        this.state = {
            params: {
                uuid: props.app ? props.app : null,
                name: null,
                package_id: null,
                auto_start: false,
            },
            versions: [],
            requestVersions: [],
            addingVersion: false,
            showChangelog: false,
            saving: false,
        }

        this.nameChangeHandler.bind(this);
        this.packageChangeHandler.bind(this);
    }

    componentDidMount() {
        if(this.props.app) {
            getAppVersions(this.props.user.accessToken, this.props.customer, this.props.app)
            .then(res => {
                let params = this.state.params;
                params.uuid = res.application_uuid;
                params.name = res.application_name;
                params.package_id = res.application_package_id;
                params.auto_start = res.application_auto_start;
                const versions = res.versions;
                this.setState({ params, versions });
            })
        }
    }

    saveHandler = () => {
        this.setState({ saving: true });
        console.log(this.state.params);
        
        createApplication(this.props.user.accessToken, this.props.customer, this.state.params)
        .then(res => {
            console.log("created application with uuid: " + res.application_uuid);
            if(this.state.requestVersions.length > 0) {
                console.log(this.state.requestVersions);
                for (let i = 0; i < this.state.requestVersions.length; i++) {
                    const version = this.state.requestVersions[i];
                    this.uploadVersion(res.application_uuid, version);
                }
            }
            else {
                console.log("No versions to edit");
                this.props.onFinish(res.application_uuid);
            }
        })
        .catch(err => {
            this.setState({ saving: false });
            console.log(err.response);
        })
    }

    uploadVersion = (applicationUuid, version) => {
        console.log((version.apkFile ? "uploading" : "editting") + "version: " + version.params.version_name);
        if(version.apkFile) {
            createApplicationVersion(this.props.user.accessToken, this.props.customer, applicationUuid, version.params, version.apkFile)
            .then(res => {
                console.log("uploaded version: " + version.params.version_name);
                this.uploadCount++;
                if(this.uploadCount === this.state.requestVersions.length) {
                    this.props.onFinish(applicationUuid);
                }
            })
            .catch(err => {
                console.log(err.response);
            });
        }
        else {
            updateApplicationVersion(this.props.user.accessToken, this.props.customer, applicationUuid, version.params)
            .then(res => {
                console.log("editted version: " + version.params.version_name);
                this.props.onFinish(applicationUuid);
            })
            .catch(err => {
                this.setState({ saving: false });
                console.log(err.response);
            });
        }
    }

    bootAppChangeHandler = checked => {
        let params = this.state.params;
        params.auto_start = checked;
        this.setState({ params });
    }

    openAppVersionModal = () => {
        this.setState({ addingVersion: true, versionToEdit: null });
    }

    closeAppVersionModal = (applicationVersion, apkFile) => {
        console.log(apkFile);
        
        if(applicationVersion) {
            let requestVersions = this.state.requestVersions;
            let versions = this.state.versions;
            requestVersions = requestVersions.filter(v => {
                if(applicationVersion.edit_id) {
                    console.log(applicationVersion.edit_id);
                    return applicationVersion.edit_id !== v.params.edit_id;
                }
                else if(applicationVersion.uuid) {
                    return applicationVersion.uuid !== v.params.uuid;
                }
                else {
                    return true;
                }
            });
            if(applicationVersion.edit_id) {
                let version = versions.filter(version => version.edit_id === applicationVersion.edit_id)[0];
                version.application_version_name = applicationVersion.version_name;
                version.application_version_code = applicationVersion.version_code;
                version.application_file_download = apkFile ? null : applicationVersion.file_link;
                version.application_file_size_kb = apkFile ? apkFile.size * 0.001 : applicationVersion.file_size;
                version.application_version_min_sdk = applicationVersion.min_sdk;
                version.application_version_changelog = applicationVersion.change_log;

                versions = versions.filter(v => v.edit_id !== applicationVersion.edit_id);
                versions.push(version);
            }
            else if(applicationVersion.uuid) {
                let version = versions.filter(version => version.application_version_uuid === applicationVersion.uuid)[0];
                version.application_version_name = applicationVersion.version_name;
                version.application_version_code = applicationVersion.version_code;
                version.application_file_download = apkFile ? null : applicationVersion.file_link;
                version.application_file_size_kb = apkFile ? apkFile.size * 0.001 : applicationVersion.file_size;
                version.application_version_min_sdk = applicationVersion.min_sdk;
                version.application_version_changelog = applicationVersion.change_log;

                versions = versions.filter(v => v.application_version_uuid !== version.application_version_uuid);
                versions.push(version);
            }
            else {
                versions.push({
                    edit_id: this.currentEditId,
                    application_version_uuid: null,
                    application_version_name: applicationVersion.version_name,
                    application_version_code: applicationVersion.version_code,
                    application_file_download: null,
                    application_file_size_kb: apkFile.size * 0.001,
                    application_version_min_sdk: applicationVersion.min_sdk,
                    application_version_changelog: applicationVersion.change_log,
                });
            }
            if(!applicationVersion.edit_id) {
                applicationVersion.edit_id = this.currentEditId;
                this.currentEditId++;
            }
            requestVersions.push({params: applicationVersion, apkFile: apkFile});
            this.setState({ addingVersion: false, versionToEdit: null, requestVersions, versions });
            console.log(requestVersions);
        }
        else {
            this.setState({ addingVersion: false, versionToEdit: null });
        }
    }

    editAppVersion = uuid => {
        let version = this.state.versions.filter(version => version.application_version_uuid === uuid)[0];
        if(!version) {
            version = this.state.versions.filter(version => version.edit_id === uuid)[0];
        }
        const versionToEdit = {
            edit_id: version.edit_id,
            uuid: version.application_version_uuid,
            changeLog: version.application_version_changelog,
            versionName: version.application_version_name,
            versionCode: version.application_version_code,
            minSdk: version.application_version_min_sdk,
            fileSize: version.application_file_size_kb,
            fileLink: version.application_file_download,
        }
        this.setState({ versionToEdit, addingVersion: true });
    }

    nameChangeHandler = event => {
        let params = this.state.params;
        params.name = event.target.value;
        this.setState({ params });
    }

    packageChangeHandler = event => {
        let params = this.state.params;
        params.package_id = event.target.value;
        this.setState({ params });
    }

    render() {
        return (
            <div className="apps-editor">
                <div className="editor">
                    <div className="tab-header">
                        <h1>{(this.props.app ? "Edit" : "Add") + " App"}</h1>
                        <Button outline onClick={() => this.props.onFinish()}>Cancel</Button>
                        <Button loading={this.state.saving} onClick={() => this.saveHandler()} disabled={!verifyApplication(this.state.params.name, this.state.params.package_id, this.state.versions)}>Save</Button>
                    </div>
                    <Input
                        title="Name"
                        placeholder="App name"
                        defaultValue={this.state.params.name}
                        onChange={this.nameChangeHandler}
                    />
                    <Input
                        title="Package name"
                        placeholder="com.example.app"
                        defaultValue={this.state.params.package_id}
                        onChange={this.packageChangeHandler}
                    />
                    <Switch 
                        title="Launch on boot"
                        onChange={this.bootAppChangeHandler}
                        checked={this.state.params.auto_start}
                    />
                    <Table 
                        small 
                        title="App versions"
                        data={this.state.versions}
                        keyMap="appVersions"
                        addAction={() => this.openAppVersionModal()}
                        onChangeLog={changeLog => this.setState({showChangelog: true, changeLog})}
                        addTitle="Upload new version"
                        onEdit={this.editAppVersion}
                    />
                    <div className="footer">
                        <div/>
                        <Button outline onClick={() => this.props.onFinish()}>Cancel</Button>
                        <Button loading={this.state.saving} onClick={() => this.saveHandler()} disabled={!verifyApplication(this.state.params.name, this.state.params.package_id, this.state.versions)}>Save</Button>
                    </div>
                    {this.state.addingVersion ? <AppVersionAdder 
                        onClose={this.closeAppVersionModal} 
                        versionToEdit={this.state.versionToEdit}
                        user={this.props.user}
                        customer={this.props.customer}
                        app={this.props.app}
                    /> : null}
                    {this.state.showChangelog ? <AppVersionAdder changeLogOnly changeLog={this.state.changeLog} onClose={() => this.setState({showChangelog: false, changeLog: null})}/> : null}
                </div>
            </div>
        )
    }
}
