import Axios from "axios";

export const getCustomers = accessToken => {
    const request = Axios.get('/auth/users/me/', {headers: {'Authorization': 'Bearer ' + accessToken}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data.customers);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getAppsInGroup = (accessToken, customer, group, column, order) => {
    const request = Axios.get('/api/v1/device_group/apps', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, device_group_uuid: group, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getContentInGroup = (accessToken, customer, group, column, order) => {
    const request = Axios.get('/api/v1/device_group/content_builds', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, device_group_uuid: group, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getDevices = (accessToken, customer, column, order, page, pageSize) => {
    const request = Axios.get('/api/v1/devices', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, page: page ? page : null, size: pageSize ? pageSize : null, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getDevicesInGroup = (accessToken, customer, group, column, order) => {
    const request = Axios.get('/api/v1/device_group/devices', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, device_group_uuid: group, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
            console.log(err.response);
        })
    });
};

export const getDeviceGroups = (accessToken, customer, column, order, page, pageSize) => {
    const request = Axios.get('/api/v1/device_groups', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, page: page ? page : null, size: pageSize ? pageSize : null, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getApps = (accessToken, customer, column, order, page, pageSize) => {
    const request = Axios.get('/api/v1/applications', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, page: page ? page : null, size: pageSize ? pageSize : null, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getAppVersions = (accessToken, customer, application, column, order) => {
    const request = Axios.get('/api/v1/application', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, application_uuid: application, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
};

export const getColors = (accessToken, customer) => {
    const request = Axios.get('/api/v1/device_group_colors/', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const saveDeviceGroup = (accessToken, customer, params) => {
    const request = Axios.post('/api/v1/device_group/insert_or_update', params, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json'}, params: {customer_uuid: customer}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const deleteDeviceGroup = (accessToken, customer, group) => {
    const request = Axios.delete('/api/v1/device_group/delete', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, device_group_uuid: group}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const createApplication = (accessToken, customer, params) => {
    const request = Axios.post('/api/v1/application/insert_or_update', params, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json'}, params: {customer_uuid: customer}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const updateApplicationVersion = (accessToken, customer, app, params) => {
    const request = Axios.post('/api/v1/application_version/insert_or_update', params, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json'}, params: {customer_uuid: customer, application_uuid: app}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const createApplicationVersion = (accessToken, customer, app, params, apkFile) => {
    const request = Axios.post('/api/v1/application_version/insert_or_update', params, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json'}, params: {customer_uuid: customer, application_uuid: app}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                if(res.data.application_version_uuid) {
                    const uploadRequest = Axios.put('/api/v1/application_version/file_upload', apkFile, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'multipart/form-data', 'Content-Disposition': 'attachment; filename=' + apkFile.name}, params: {customer_uuid: customer, application_version_uuid: res.data.application_version_uuid}});
                    uploadRequest.then(res => {
                        if(res.data) {
                            resolve(res.data);
                        }
                        else {
                            reject("Problem with data");
                        }
                    })
                    uploadRequest.catch(err => {
                        reject(err);
                    })
                }
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const deleteApplication = (accessToken, customer, application) => {
    const request = Axios.delete('/api/v1/application/delete', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, application_uuid: application}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const getContentBuilds = (accessToken, customer, state, column, order) => {
    const request = Axios.get('/api/v1/content_builds', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, state: state, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const createContentBuild = (accessToken, customer, params) => {
    const request = Axios.post('/api/v1/content_build/insert_or_update', params, { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const uploadFileToFileset = (accessToken, customer, fileSet, file, onUploadProgress) => {
    const request = Axios.put('/api/v1/file_set/file_upload', file, { headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'multipart/form-data', 'Content-Disposition': 'attachment; filename=' + file.name}, params: {customer_uuid: customer, file_set_uuid: fileSet}, onUploadProgress: onUploadProgress});
    console.log(customer);
    
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const getReleases = (accessToken, customer, column, order) => {
    const request = Axios.get('/api/v1/releases', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, 'sort_field': column, 'sort_order': order}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const getContentBuildVersionDetails = (accessToken, customer, contentBuildVersion) => {
    const request = Axios.get('/api/v1/content_build_version', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, content_build_version_uuid: contentBuildVersion}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const getFileSet = (accessToken, customer, fileSet, application, contentBuild) => {
    const request = Axios.get('/api/v1/file_set', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, content_build_uuid: contentBuild, file_set_uuid: fileSet, application_uuid: application}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const createRelease = (accessToken, customer, contentBuildVersion, deviceGroup, params) => {
    const request = Axios.post('/api/v1/content_build_version/release', params, { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, device_group_uuid: deviceGroup, content_build_version_uuid: contentBuildVersion}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const deleteFileFromFileset = (accessToken, customer, fileSet, file) => {
    const request = Axios.delete('/api/v1/file_set/delete_file', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, file_set_uuid: fileSet, file_uuid: file }});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const deleteContentBuild = (accessToken, customer, contentBuild) => {
    const request = Axios.delete('/api/v1/content_build/delete', { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, content_build_uuid: contentBuild }});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const withdrawContentBuild = (accessToken, customer, contentBuildVersionRelease) => {
    const request = Axios.patch('/api/v1/withdraw/content_build_version', null, { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, content_build_version_release_uuid: contentBuildVersionRelease }});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const withdrawApplicationVersion = (accessToken, customer, applicationVersionRelease) => {
    const request = Axios.patch('/api/v1/withdraw/application_version', null, { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer, application_version_release_uuid: applicationVersionRelease }});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            reject(err);
        })
    });
}

export const createFileSet = (accessToken, customer, type, oldFileset) => {
    const request = Axios.post('/api/v1/file_set/insert_or_update', {uuid: null, type: type, parent_file_set_uuid: oldFileset}, { headers: {'Authorization': 'Bearer ' + accessToken}, params: {customer_uuid: customer}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        })
        request.catch(err => {
            console.log(err.response);
            reject(err);
        })
    });
}

export const exportBuild = async (customerSlug) => {
    try {
        const response = await Axios.post(
            `https://export.content.tapart.me/export/${customerSlug}`, {}
            ,{
                auth: {
                    username: "dms",
                    password: "asdasd"
                }
            }
        );  
        return response.data;
    } catch(e) {
        return e.response.data
    }
}